export const eventsDE = [
  {
    id: '224',
    url: 'darkwave-party',
    title: 'Darkwave - sheep n wolves - party',
    description: `<p>Provokatsia Project in a atmospheric electro dark wave mode!</p>
    <p>Macht euch bereit, mit den elektrisierenden Beats der 80er und 90er Jahre im Lauschangriff in Berlin in die Vergangenheit zu reisen! Das Provokatsia Project Team lädt euch zu einem unvergesslichen Abend ein, an dem die Vergangenheit in einer Symphonie aus Darkwave und atmosphärischen elektronischen Melodien auf die Gegenwart trifft. Schließt euch uns für eine Party an, die mehr verspricht als nur eine einfache Rückkehr in die Zeit.</p>
    <p>Die DJs Pikoz Apikoz und Dj Kostas werden am Steuer sein und uns durch eine musikalische Odyssee mit ihren hervorragenden DJ-Sets führen. Ob ihr leidenschaftliche  Fans der Ära seid oder einfach etwas Neues erleben möchtet, das ist der Ort, an dem ihr am 16. März sein solltet.</p>
    <p>Verpasst nicht diese Reise durch die Dark Wave-Klänge der 80er und 90er Jahre in einer absolut elektrischen Atmosphäre!</p>
    <p>Tipp: Die Anzahl der verfügbaren Tickets/Plätze ist begrenzt. Kommen Sie deshalb möglichst früh! ;)</p>`,
    poster: 'darkwave-party.jpg',
    tip: '',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2024-02-16T21:00:00.349Z',
        endDate: '2024-02-17T06:00:00.349Z',
        title: '',
        poster: '',
        artists: [
          {
            name: 'Dj Pikoz Apikoz',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Dj Kostas',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
        ],
        description: '',
        eventType: 'DJ Set',
        video: '',
        time: '21:00',
        doorsOpen: '21:00',
        entrance: '',
        googleLocation:
          'https://www.google.com/maps/place/Lauschangriff/@52.5182464,13.4553066,15z/data=!4m6!3m5!1s0x47a84e6653637a9d:0xd32edb6abbf17c08!8m2!3d52.5182464!4d13.4553066!16s%2Fg%2F11bxh9tjcp?entry=ttu',
        locationName: 'Lauschangriff',
        locationAddress: 'Rigaer Str. 103, 10247 Berlin',
      },
    ],
    supplementary: '',
  },
  {
    id: '223',
    url: 'rebetiko-duets',
    title: 'Rebetiko duets!',
    description: `<p>Liebe Freunde,</p><p>das Team des Provokatsia-Projekts setzt seinen Weg mit einer weiteren dreitägigen
    Bildungs- und Musikveranstaltung in Zusammenarbeit mit dem Greek Music Workshop und
    der SKB (TU Berlin) fort. Wir freuen uns, drei wunderbare Musiker aus Griechenland
    begrüßen zu dürfen, die uns in einer Hommage an berühmte Duette des Rembetiko auf eine
    Zeitreise mitnehmen.
    <p>
    Genauer gesagt wird es am Freitag, dem 23. Februar, im Café TELquel der TU Berlin im
    Beisein des Drehbuchautors Anestis Barbatsis eine Vorführung des Dokumentarfilms
    „Broken Sound“ geben. Der Film bezieht sich auf das traditionelle Instrument, die Bouzouki,
    sowie auf die Einflüsse, die es im Laufe der Zeit erhalten hat. Es folgt ein kurzes Rembetiko-
    Konzert. Durch den Rest des Abends wird uns ein DJ mit dem Thema „Weltmusik“ begleiten.    </p>
    <p>Die musikalische Reise geht am Samstag, dem 24. Februar, im Bavul weiter. Die Gastmusiker
    Anestis Barbatsis (Gesang, Bouzouki), Lambrini Astrid Gioti Andersson (Gesang) und Iason
    Kalogiros (Gesang, Bouzouki) werden zusammen mit den Berliner Musikern Dinos Bouzanis
    (Gitarre), Sanne Möricke (Akkordeon) und Thodoris (Kontrabass) live Lieder aus den
    berühmten Duetten Chioris/Haskil und Tsitsanis/Ninou sowie andere Rembetika spielen.</p> 
    <p>Und schließlich findet am Sonntag, dem 25. Februar, ein vierstündiger Erlebnisworkshop im
    Café Karanfil statt. Hier haben die Teilnehmer die Möglichkeit, die Gesangsweisen von
    Marika Ninos und Stelle Haskil unter der Leitung der renommierten Ethnomusikologin
    Lambrini Astrid Gioti Andersson mit Erfahrung auf dem Gebiet traditioneller Klänge zu
    entdecken. Ein Workshop für alle, die sich für Rembetiko interessieren, Gesangserfahrung
    wird nicht benötigt.</p>
    
    <p>Wir warten auf Euch!</p>
    <h3>Anestis Barbatsis</h3>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/ZPQCqJP3m4w?si=pCAQrJERUlNDHKQl" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
    <h3>Labrini Astrid Gioti Andersson</h3>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/I6h8V0JEcHY?si=gCnKlfksYzHSPsp-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
    <h3>Dokumentarfilm trailer</h3>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/4Xf8JWi2jFg?si=qSCdYlyYiFn6eN0a" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`,
    poster: 'rebetiko-duets.jpg',
    tip: '',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2024-02-23T19:00:00.349Z',
        endDate: '2024-02-25T02:00:00.349Z',
        title: 'Dokumentarfilm Aufführung, Mini Konzert & Dj set',
        subtitle: `Idee und Umsetzung: Anestis Barbatsis<br/>
          Regie: Foivos Kontogiannis<br/>
          <i>Auf Griechisch mit englischen Untertitel</i>`,
        poster: '',
        artists: [],
        description: `
          <p>Der Musikdokumentarfilm „Broken Sound“ ist ein gemeinsames Werk von Anestis Barbatsis und Fivos Kontogiannis und wurde von Foss Productions gemeinsam mit dem Hellenic Film Center produziert. Fivos Kontogiannis, verantwortlich für die Regie, und Anestis Barbatsis, verantwortlich für die Idee, Recherche, Redaktion, Musikbearbeitung sowie die Auswahl und Interviews der Protagonisten und anderer Sprecher, führen uns auf eine Erinnerungsreise zum Klang des beliebtesten Volksinstrumentes des Landes, erzählt aus der Sicht großer Persönlichkeiten der heutigen Geschichte der Bouzouki. </p>
          <p>Von den dunklen Tekédes (Mehrzahl von Tekés – aus dem Türkischen, in Griechenland im Zusammenhang mit dem Rembetiko häufig als Bezeichnung für Lokale verwendet, in denen man Haschisch rauchen konnte) in den Jahren der Illegalität bis hin zu den großen Bühnen der griechischen Musikgeschichte entfaltet die Bouzouki ihre Seele in den Händen der Musiker, die sie liebten und bekannt machten. Teil dieser visualisierten Erzählung und präsent in diesem

          anspruchsvollen Werk sind große Musiker wie Dimitris Vyzas, Giorgos Dramalis, Manolis Karantinis, Vangelis Liolios, Giannis Moraitis, Christos Nikolopoulos, Giannis Papavasileiou, Kostas Papadopoulos, Manolis Pappos, Thanasis Polykrandriotis, Panagiotis Stergiou, Thymios Stouraitis, Nikos Tatasopoulos, Vangelis Trigas und Diamantis Chiotis , die uns mit ihren Taximia (Improvisationen) und ihren faszinierenden Erzählungen melodisch auf eine Reise durch die Vergangenheit, die Gegenwart und die Zukunft der Bouzouki mitnehmen. </p>
          <p>„Broken Sound“ folgt der musikalischen Entwicklung der Bouzouki. Der Film präsentiert einen historischen und musikalischen Überblick sowie eine kulturelle und soziale Darstellung der Entwicklung der griechischen Gesellschaft durch Interviews mit bedeutenden Persönlichkeiten der heutigen Geschichte des Instruments, kombiniert mit seltenem Archivmaterial. </p>
        `,
        supplementary: `
          <h2>Angaben zum Film</h2>
          <p>Idee und Recherche: Anestis Barbatsis<br />
            Drehbuch: Anestis Barbatsis, Foivos Kontogiannis
          </p>
          <p>Regie: Foivos Kontogiannis </p>
          <p>Redaktion und Musikbearbeitung: Anestis Barbatsis</p>
          <p>
            Produkion: Foss Productions<br />
            Co-Produktion: Hellenic Cinema Center, Faliro House, EKOME<br />
            Produzent: Stelios Kotionis<br />
            Ausführender Produzent: Christos V. Konstantakopoulos<br />
            Kamera: Manu Tilinski<br />
            Bearbeitung: George Georgopoulos<br />
            Ton: Stavros Avramidis, Leandros Dounis<br />
          </p>
        `,
        eventType: 'Aufführung & Konzert',
        videoId: '4Xf8JWi2jFg',
        time: '19:00 Aufführung | 22:00 dj set',
        doorsOpen: '18:30',
        entrance: 'Eintritt Frei',
        googleLocation:
          'https://www.google.com/maps/place/Department+of+Media+Science+Institute+of+Language+and+Communication,+Ernst-Reuter-Platz+7,+10587+Berlin/@52.5130599,13.3202022,20z/data=!4m6!3m5!1s0x47a8511c46ef3243:0x12b970f58d57050c!8m2!3d52.5130317!4d13.3200439!16s%2Fg%2F12hk180jv?entry=ttu',
        locationName: 'Cafe TELquel (TU Berlin)',
        locationAddress: 'Ernst-Reuter-Platz 7, 10587 Berlin',
      },
      {
        id: '2',
        startDate: '2024-02-24T19:00:00.349Z',
        endDate: '2024-02-24T02:00:00.349Z',
        title: 'Rebetiko Konzert',
        poster: '',
        artists: [
          {
            name: 'Dinos Bouzanis',
            speciality: 'Gitarre',
            image: 'ntinos.jpg',
            youtube: 'https://www.youtube.com/@unterrichtgitarrerebetikou1659',
            spotify: '',
          },
          {
            name: 'Sanne Möricke',
            speciality: 'Akkordeon',
            image: 'sanne.jpg',
            youtube: 'https://www.youtube.com/watch?v=qvfUrYWJ7Mo',
            spotify: '',
          },
          {
            name: 'Lamprini Astrid Yioti Andersson ',
            speciality: '',
            image: 'labrini.jpg',
            youtube: 'https://www.youtube.com/@LabriGiottoofficial',
            spotify: '',
            description: `<h2>Labrini Astrid Gioti Andersson </h2>
            <p>Lambrini Astrid Gioti Andersson ist eine griechische Performerin, Lehrerin, Radioproduzentin und
            Ethnomusikologin. Sie absolvierte die Pallini Musikhochschule, besitzt ein Diplom für klassisches
            Klavier des Nationalen Konservatoriums von Athen, einen Abschluss in Musikwissenschaften und
            einen Master-Abschluss in Ethnomusikologie der Irish World Academy of Music & Dance (University
            of Limerick) mit einem Stipendium der Attica Tradition Foundation.</p>
              <p>Sie begann ihre Beschäftigung mit keltischer Musik im Alter von 13 Jahren und schrieb 2006 ihre
              Magisterarbeit zum Thema „Sean-Nós Gesangsstil aus der Region West Cork Gaeltacht“, wobei sie
              sich auf den Gesangsstil von Eilís Ni Shúilleabháin, Iarla Ó Lionáird und Elisabeth Cronin
              konzentrierte.</p> 
               <p>Im Zeitraum 2007 und 2008 unterrichtete sie byzantinischen, polyphonen epirotischen Gesang und
               Rembetiko in Form von Seminaren and der Irish World Academy of Music and Dance der University
               of Limerick.</p>
             <p>Sie wurde von Elaine Cormican, Eilís Ni Shúilleabháin, Deirdre Scanlon, Micheál Ó Súilleabháin,
             Muireann Nic Amhlaoibh, Bobby McFerrin, Mamak Khadem, Åsa & Esbjörn Hogmark, Nikos
             Mamagakis, Ross Daly und Kelly Thomá, Maria Koti, Martha Mavroídi, Haris Lamprakis, Spiros
             Goumas, Veronica Doubleday, John Bailey, Johanna Bölia und Vicky Swan in traditioneller Musik und
             verschiedenen Stilen unterrichtet.</p>
              <p>Seit dem Jahr 2000 ist sie professionelle Musikerin mit Auftritten auf Musikbühnen, in Tavernen und
              auf Konzerten. Außerdem unterrichtet sie Klavier, Rembetika, traditionelle griechische und
              nordeuropäische Musik, Gesangsstil und -theorie in Irland, Schottland, Schweden, Norwegen,
              Deutschland und Griechenland.</p>
              <p>Sie trat im Akropolismuseum (Projekte mit der irischen Botschaft), im Kykladenmuseum (irische
                Botschaft), beim ERT, beim Athens Celtic Music Festival, beim Athens Irish Festival, bei Irish Wings,
                beim Paxos Festival und Medieval Festival of Rhoddes auf sowie auf verschiedenen Musikbühnen in
                Griechenland, Irland (IWAMD, Bewley’s Cafe Theater), Schweden (Esi Tobo) und Schottland (Cowdray
                Hall, Blue Lamp).</p>
              <p>Sie ist Pionierin bei der Förderung des traditionellen irischen, schottischen und skandinavischen
              Gesangs in Griechenland mit Seminaren seit 2010, gründete und leitet den Curfa Gaelic Nordic Choir
              mit Studenten der Seminare seit 2017.</p>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/I6h8V0JEcHY?si=al0fTcJR0GPX0AdU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <p>Lambrini Astrid Gioti Andersson fördert seit 2006 die irische und keltische Kultur mit Konzerten und
              Veranstaltungen in Zusammenarbeit mit der irischen Botschaft, Culture Ireland und der Greek Irish
              Society. Sie ist eine der Gründer des Athens Celtic Musik Festival (2014 – 2019).</p>
              <p>Das ITMA (Irish Traditional Music Archive) verzeichnete sie in seinen Archiven als erste griechische
              Frau, die in der irischen (gälischen) Sprache Sean-nós sang. Sie hat Sean-nós vor dem irischen
              Präsidenten Michael D. Higgins in Athen gesungen.</p>
              <p>Im Jahr 2022 spielte sie griechische Musik für den preisgekrönten Dokumentarfilm „An Buachaill
              Gealghaireach“ (Das lachende Kind), zusammen mit zwei berühmten irischen traditionellen
              Musikern, Liam O’Maonlai und Dave Power.

              </p>
              <p>Sie ist eines der Gründungsmitglieder der Band Sólastas (Athens Celtic Music Festival, Gagarin,
                Trianon, Ianos, Athens Irish Festival, Rhodes Medieval Festival, Goethe-Institut, Hellenic American
                Union und verschiedene Projekte mit der irischen Botschaft).</p>
              <p>Von 2017 bis 2021 startete sie das Projekt Nyckelharpa Griechenland, nachdem sie mit ihrer Band
              Sólastas die erste Nyckelharpa aus Schweden mitgebracht hatte, um skandinavische Musik in
              Griechenland zu fördern.

              </p>
              <p>Im Oktober 2019 wurde sie in das interkulturelle Orchester der Alternativbühne der Nationaloper
              aufgenommen.</p>
            `,
          },
          {
            name: 'Anestis Barbatsis',
            speciality: 'Bouzouki/Gesang',
            image: 'anestis.png',
            youtube: 'https://www.youtube.com/@anestisbarbatsis8988',
            spotify: '',
          },
          {
            name: 'Iason Kalogiros',
            speciality: 'Bouzouki/Gesang',
            image: 'iason.jpeg',
            youtube: 'https://www.youtube.com/watch?v=nRoZk_nlmH4',
            spotify: '',
          },
        ],
        description:
          '<p>Die musikalische Reise geht am Samstag, dem 24. Februar, im Bavul weiter. Die Gastmusiker Anestis Barbatsis (Gesang, Bouzouki), Lambrini Astrid Gioti Andersson (Gesang) und Iason Kalogiros (Gesang, Bouzouki) werden zusammen mit den Berliner Musikern Dinos Bouzanis (Gitarre), Sanne Möricke (Akkordeon) und Thodoris (Kontrabass) live Lieder aus den berühmten Duetten Chioris/Haskil und Tsitsanis/Ninou sowie andere Rembetika spielen.</p>',
        eventType: 'Konzert',
        video: '',
        time: '19:00',
        doorsOpen: '18:30',
        entrance: '',
        googleLocation:
          'https://www.google.com/maps/place/BAVUL+-+Kunst+%26+Kultur+Caf%C3%A9/@52.5093458,13.4121714,17z/data=!3m1!4b1!4m6!3m5!1s0x47a84f3c13e1227b:0x259905d739f1b5c2!8m2!3d52.5093458!4d13.4121714!16s%2Fg%2F11fm2m1wvj?entry=ttu',
        locationName: 'BAVUL - Kunst & Kultur Café',
        locationAddress: 'Annenstraße 13, 10179 Berlin',
      },
      {
        id: '3',
        startDate: '2024-02-25T12:00:00.349Z',
        endDate: '2024-02-25T16:00:00.349Z',
        title: 'Workshop in Rebetiko Stimme / Gesang',
        poster: '',
        artists: [
          {
            name: 'Lamprini Astrid Yioti Andersson ',
            speciality: '',
            image: 'labrini.jpg',
            youtube: 'https://www.youtube.com/@LabriGiottoofficial',
            spotify: '',
            description: `<h2>Labrini Astrid Gioti Andersson </h2>
            <p>Lambrini Astrid Gioti Andersson ist eine griechische Performerin, Lehrerin, Radioproduzentin und
            Ethnomusikologin. Sie absolvierte die Pallini Musikhochschule, besitzt ein Diplom für klassisches
            Klavier des Nationalen Konservatoriums von Athen, einen Abschluss in Musikwissenschaften und
            einen Master-Abschluss in Ethnomusikologie der Irish World Academy of Music & Dance (University
            of Limerick) mit einem Stipendium der Attica Tradition Foundation.</p>
              <p>Sie begann ihre Beschäftigung mit keltischer Musik im Alter von 13 Jahren und schrieb 2006 ihre
              Magisterarbeit zum Thema „Sean-Nós Gesangsstil aus der Region West Cork Gaeltacht“, wobei sie
              sich auf den Gesangsstil von Eilís Ni Shúilleabháin, Iarla Ó Lionáird und Elisabeth Cronin
              konzentrierte.</p> 
               <p>Im Zeitraum 2007 und 2008 unterrichtete sie byzantinischen, polyphonen epirotischen Gesang und
               Rembetiko in Form von Seminaren and der Irish World Academy of Music and Dance der University
               of Limerick.</p>
             <p>Sie wurde von Elaine Cormican, Eilís Ni Shúilleabháin, Deirdre Scanlon, Micheál Ó Súilleabháin,
             Muireann Nic Amhlaoibh, Bobby McFerrin, Mamak Khadem, Åsa & Esbjörn Hogmark, Nikos
             Mamagakis, Ross Daly und Kelly Thomá, Maria Koti, Martha Mavroídi, Haris Lamprakis, Spiros
             Goumas, Veronica Doubleday, John Bailey, Johanna Bölia und Vicky Swan in traditioneller Musik und
             verschiedenen Stilen unterrichtet.</p>
              <p>Seit dem Jahr 2000 ist sie professionelle Musikerin mit Auftritten auf Musikbühnen, in Tavernen und
              auf Konzerten. Außerdem unterrichtet sie Klavier, Rembetika, traditionelle griechische und
              nordeuropäische Musik, Gesangsstil und -theorie in Irland, Schottland, Schweden, Norwegen,
              Deutschland und Griechenland.</p>
              <p>Sie trat im Akropolismuseum (Projekte mit der irischen Botschaft), im Kykladenmuseum (irische
                Botschaft), beim ERT, beim Athens Celtic Music Festival, beim Athens Irish Festival, bei Irish Wings,
                beim Paxos Festival und Medieval Festival of Rhoddes auf sowie auf verschiedenen Musikbühnen in
                Griechenland, Irland (IWAMD, Bewley’s Cafe Theater), Schweden (Esi Tobo) und Schottland (Cowdray
                Hall, Blue Lamp).</p>
              <p>Sie ist Pionierin bei der Förderung des traditionellen irischen, schottischen und skandinavischen
              Gesangs in Griechenland mit Seminaren seit 2010, gründete und leitet den Curfa Gaelic Nordic Choir
              mit Studenten der Seminare seit 2017.</p>
              <iframe width="560" height="315" src="https://www.youtube.com/embed/I6h8V0JEcHY?si=al0fTcJR0GPX0AdU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
              <p>Lambrini Astrid Gioti Andersson fördert seit 2006 die irische und keltische Kultur mit Konzerten und
              Veranstaltungen in Zusammenarbeit mit der irischen Botschaft, Culture Ireland und der Greek Irish
              Society. Sie ist eine der Gründer des Athens Celtic Musik Festival (2014 – 2019).</p>
              <p>Das ITMA (Irish Traditional Music Archive) verzeichnete sie in seinen Archiven als erste griechische
              Frau, die in der irischen (gälischen) Sprache Sean-nós sang. Sie hat Sean-nós vor dem irischen
              Präsidenten Michael D. Higgins in Athen gesungen.</p>
              <p>Im Jahr 2022 spielte sie griechische Musik für den preisgekrönten Dokumentarfilm „An Buachaill
              Gealghaireach“ (Das lachende Kind), zusammen mit zwei berühmten irischen traditionellen
              Musikern, Liam O’Maonlai und Dave Power.

              </p>
              <p>Sie ist eines der Gründungsmitglieder der Band Sólastas (Athens Celtic Music Festival, Gagarin,
                Trianon, Ianos, Athens Irish Festival, Rhodes Medieval Festival, Goethe-Institut, Hellenic American
                Union und verschiedene Projekte mit der irischen Botschaft).</p>
              <p>Von 2017 bis 2021 startete sie das Projekt Nyckelharpa Griechenland, nachdem sie mit ihrer Band
              Sólastas die erste Nyckelharpa aus Schweden mitgebracht hatte, um skandinavische Musik in
              Griechenland zu fördern.

              </p>
              <p>Im Oktober 2019 wurde sie in das interkulturelle Orchester der Alternativbühne der Nationaloper
              aufgenommen.</p>
            `,
          },
        ],
        description: `    
          <h3>INTRODUCTION TO THE SINGING STYLES OF MARIKA NINOU AND STELA HASKIL</h3>
          <p>With the ethnomusicologist Labrini Astrid Gioti Andersson</p>
         
          <p>A 4-hour workshop for anyone interested in an introduction to the rebetika songs and with the focus to the voices of Marika Ninou and Stella Haskil. 
          We are about to explore the singing style (technique, phrasing etc. ) through some of  the songs from their vast repertoire.</p>

          <p>You don't need to have any  previous experience in singing or music knowledge. The language of the tuition is Εnglish ,but the songs are in Greek (Translation  with detailed pronunciation  is included). Furthermore, a certificate of attendance will be provided.</p>
          <p><b>⚠️ Important information ⚠️</b><br>
          If you’re interested in attending the workshop, please secure your spot by sending an email to <b>provokatsia.project@gmail.com</b> no later than February 23rd.
          </p>
          <p>The workshop in details:</p>
          <ul>
            <li>Vocal Warm up 20 minutes</li>
            
            <li>Short lecture 20 minutes </li>
            
            <li>10 minutes break </li>
            
            <li>Repertoire 60 minutes </li>
            
            <li>Break 10 mins</li>
            
            <li>Repertoire 60mins </li>
            
            <li>Break 10mins</li>
            
            <li>Repertoire 50mins</li>
        </ul>
        <h3>Λαμπρινή Astrid Γιώτη Andersson</h3>
        <p><iframe width="560" height="315" src="https://www.youtube.com/embed/I6h8V0JEcHY?si=gCnKlfksYzHSPsp-" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>`,
        eventType: 'Workshop',
        video: '',
        time: '12:00',
        doorsOpen: '11:30',
        entrance: '',
        googleLocation:
          'https://www.google.com/maps/place/Cafe+Karanfil/@52.4799811,13.4228092,17z/data=!3m1!4b1!4m6!3m5!1s0x47a84fbee9fa6ebf:0xb129429743ec5b46!8m2!3d52.4799811!4d13.4228092!16s%2Fg%2F11gff6v8_m?entry=ttu',
        locationName: 'Cafe Karanfil',
        locationAddress: 'Weisestraße 3, 12049 Berlin',
      },
    ],
    supplementary: `<h2>Rembetika – der griechische Blues</h2>
        <p><i>"Ein ähnliches künstlerisches Schaffen lässt sich in Amerika beim Blues, in Brasilien beim Samba und in Jamaika beim Reggae beobachten."</i></p>
        <p><i>"Der Rembetiko ist ein urbanes Volkslied, dessen Wurzeln auf byzantinische Musik und griechische Volkslieder zurückgehen."</i></p>
        <p><i>"Rembetis: Ungehorsam, rebellisch, unabhängig."</i></p>
        <p><i>"Rembetika sind Lieder des Herzens und nur, wer sich ihnen mit reinem Gefühl nähert, fühlt und genießt sie."</i></p>
        <p><i>"Dem Rembetiko gelingt es, Sprache, Musik und Bewegung in wunderbarer Einheit zu verbinden."</i></p>
        <p><i>"Von der Komposition bis zur Ausführung werden instinktiv die Voraussetzungen für dieses dreifache Zusammenwirken geschaffen, das manchmal zur Perfektion zu gelangen scheint und an eine antike Tragödie erinnert."</i></p>
        <p>Auszug aus dem Buch „Rembetiko-Lieder“ von Ilias Petropoulos</p>
        <p><i>"Das Rembetiko-Lied ist wirklich griechisch, einzigartig griechisch."</i></p>
        <p>Manos Chatzidakis</p>
      `,
  },
  {
    id: '222',
    url: 'fikir-amlak-4dub-vibes-soundsystem',
    title: 'Fikir Amlak & 4 dub vibes soundsystem',
    description: `<p>We are proud and humble to support <a href="https://www.facebook.com/profile.php?id=100060822286025" target="_blank">4dub Vibes Soundsystem</a> to their massive event, inviting Fikir Amlak from Los Angeles, CA for the very first time in Athens Greece in a heavy bass session, delivered by their handcrafted soundsystem!</p>
    <p>Fikir Amlak has been professionally involved in the music industry for 20 years as a singer/songwriter, multi-instrumentalist and producer. In the past 8 years, Fikir Amlak has seen his music released by various international labels in the form of 56 vinyl records, 21 CD albums and 58 digital singles. Since 2014, he has become a regular on the European ‘sound system’ tour circuit, frequently performing as a singer alongside King Alpha Sound System from the UK. Fikir Amlak’s most popular album releases to date have been in collaboration with King Alpha and the American music label, Akashic Records Reggae, although his projects with San Diego based producer Brizion have also received popular support. Along with several vinyl records, albums and digital singles slated for release in the coming years, Fikir Amlak recently launched his own record label, Tikur Anbessa Records operating out Los Angeles, CA with an emphasis on limited, polyvinyl releases. </p>
    <p>All the dub lovers roll out for this one and let Athens city burn with some serious power vibes from Fikir Amlak in a highest quality sound of the 4dub Vibes Soundsystem! That will be certainly a night to remember!</p>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/E6LpIFf6TaY?si=NLbMKgCXW29fOL9p" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>`,
    poster: 'fikir-amlak-4dub-vibes-soundsystem.jpg',
    tip: '',
    isSuggested: true,
    days: [
      {
        id: '1',
        startDate: '2024-02-17T21:00:00.349Z',
        endDate: '2024-02-18T04:00:00.349Z',
        title: '',
        poster: '',
        artists: [],
        description: '',
        eventType: 'Concert',
        video: '',
        time: '23:00 GR time',
        doorsOpen: '21:00',
        entrance: '',
        googleLocation:
          'https://www.google.com/maps/place/Red+Sea+Reggae+House/@37.9777233,23.7564017,15z/data=!4m6!3m5!1s0x14a1bd50a7a390f7:0x312db5e7485e9e9e!8m2!3d37.9777233!4d23.7564017!16s%2Fg%2F1tht93zf?entry=ttu',
        locationName: 'Red Sea Reggae House | Athens',
        locationAddress: 'Ilia Potamianou 23, Athina 115 28, Greece',
      },
    ],
    supplementary: '',
  },
  {
    id: '0',
    url: 'provokatsia-on-the-road',
    title: 'Provokatsia on the road! Mini tour in Greece',
    description: `<h3>Provokatsia on the road! </h3>
    <p>Mr. Flavor Mav, & Mr. Richter Scale Madness (aka Kleissonic) have packed their vibes and they hit the road for a mini tour in Greece. Three days – three towns Agrinio | Livadia | Larissa, showcasing dj series as well as guitar solo performances. Richter Scale Madness will be performing solo with guitars playing psych heavy tunes and Flavor Mav the insane space rocker from Berlin, will be selecting tunes on those dj series.</p>
    <p>We highly recommend to not miss that one! Come along!</p>`,
    poster: 'provokatsia-on-the-road.jpg',
    tip: '',
    isSuggested: false,
    displayArtistList: true,
    hideMoreDetails: true,
    days: [
      {
        id: '1',
        startDate: '2024-01-05T19:00:00.349Z',
        endDate: '2024-01-13T04:00:00.349Z',
        title: '1st stop @ Akrovatis | Agrinio',
        poster: '',
        artists: [
          {
            name: 'Louis',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Flavor Mav',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Richter scale madness',
            speciality: 'Solo guitar performance',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
        ],
        description: '',
        eventType: 'DJ Set &  Solo guitar performance',
        video: '',
        time: '21:00',
        doorsOpen: '19:00',
        entrance: 'Free entrance',
        googleLocation:
          'https://www.google.com/maps/place/Karaiskaki+16,+Agrinio+301+31,+Greece/@38.6261395,21.4034472,17z/data=!3m1!4b1!4m10!1m2!2m1!1s16+Karaiskaki+Georgiou+Street,+30131+Agrinio!3m6!1s0x135e8a7438436d09:0x85d948583c0a19ed!8m2!3d38.6261396!4d21.4083235!15sCiwxNiBLYXJhaXNrYWtpIEdlb3JnaW91IFN0cmVldCwgMzAxMzEgQWdyaW5pb5IBEGdlb2NvZGVkX2FkZHJlc3PgAQA!16s%2Fg%2F11rpth7973?entry=ttu',
        locationName: 'Akrovatis',
        locationAddress: 'Karaiskaki 16, Agrinio 301 31, Greece',
      },
      {
        id: '2',
        startDate: '2024-01-12T19:00:00.349Z',
        endDate: '2024-01-13T04:00:00.349Z',
        title: '2nd stop @ TAF | Livadia',
        poster: '',
        artists: [
          {
            name: 'Louis',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Flavor Mav',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Richter scale madness',
            speciality: 'Solo guitar performance',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
        ],
        description: '',
        eventType: 'DJ Set &  Solo guitar performance',
        video: '',
        time: '21:00',
        doorsOpen: '19:00',
        entrance: 'Free entrance',
        googleLocation:
          'https://www.google.com/maps/place/TAF/@38.4361695,22.8749872,15z/data=!4m6!3m5!1s0x14a08fae761d808f:0x703050862564528c!8m2!3d38.4361695!4d22.8749872!16s%2Fg%2F11nn45r4sj?entry=ttu',
        locationName: 'TAF',
        locationAddress: 'I. Lappa 9, Livadia 321 00, Greece',
      },
      {
        id: '3',
        startDate: '2024-01-13T19:00:00.349Z',
        endDate: '2024-01-14T04:00:00.349Z',
        title: '3nd stop @ Wise Monkey | Larissa',
        poster: '',
        artists: [
          {
            name: 'Louis',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Flavor Mav',
            speciality: 'DJ set',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
          {
            name: 'Richter scale madness',
            speciality: 'Solo guitar performance',
            image: '',
            youtube: '',
            spotify: '',
            description: ``,
          },
        ],
        description: '',
        eventType: 'DJ Set &  Solo guitar performance',
        video: '',
        time: '21:00',
        doorsOpen: '19:00',
        entrance: 'Free entrance',
        googleLocation:
          'https://www.google.com/maps/place/Wise+Monkey+-+Urban+Vibes/@39.6350767,22.4156713,17z/data=!3m1!4b1!4m6!3m5!1s0x135889ad4c78baad:0x1630f15d80848839!8m2!3d39.6350767!4d22.4182516!16s%2Fg%2F11jgsxpk47?entry=ttu',
        locationName: 'Wise Monkey - Urban Vibes',
        locationAddress: 'Asklipiou 38, Larisa 412 22, Greece',
      },
    ],
    supplementary: '',
  },
  {
    id: '22',
    url: 'laiko-rempetiko-glenti',
    title: 'Laiko Rempetiko Glenti',
    description: `<p>Das Provokatsia Projekt lädt Euch zu einem Fest mit Rembetiko und Laika in den
    New York Pallace in Agrinio ein, wo uns die begnadeten Musiker Dinos Bouzanis
    (Gitarre), Stratos Souvatzis und Maria Vakali (Gesang), Orfeas Tsaktsiras (Bouzouki)
    und Andreas Kotsarinis (Akkordeon) zurück in eine Zeit mit bekannten und
    unbekannten Rembetiko- und Laika-Liedern führen werden.</p>

    <p>Versäumt nicht den einzigartigen Abend der Musik und Kultur.</p>
    <br />
    <b>Stratos Souvatrzis vocals, Dinos Bouzanis guitar, Zigouala</b>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/Np4XpZYPygs?si=__RLlqbMHwS0ex5Q" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
    <br />
    <b>Maria Vakali performing on TV show "Oti agapo"</b>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/t_TywytZvdw?si=JHGk-IfjaXOV7wSO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
    <br />
    <b>Tha kano ntou vre poniri - Orfeas Tsaktsiras</b>
    <p><iframe width="560" height="315" src="https://www.youtube.com/embed/4fUozYahKcQ?si=jCCpCLZczPBtX0ym" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
    `,
    poster: 'laiko-rempetiko-glenti.jpg',
    tip: '',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2024-01-07T13:00:00.349Z',
        endDate: '2024-01-07T22:00:00.349Z',
        title: '',
        poster: '',
        artists: [
          {
            name: 'Dinos Bouzanis',
            speciality: 'Guitar',
            image: 'ntinos.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Stratos Souvatrzis',
            speciality: 'Vocals',
            image: 'stratos.jpg',
            youtube:
              'https://www.youtube.com/playlist?list=PLBcA1hS7Mwmd0Ct_CygblH7H7JFGy11zv',
            spotify: '',
          },

          {
            name: 'Maria Vakali',
            speciality: 'Vocals',
            image: 'maria.jpg',
            youtube: 'https://www.youtube.com/watch?v=t_TywytZvdw',
            spotify: '',
          },
          {
            name: 'Orfeas Tsaktsiras',
            speciality: 'Bouzouki, Vocals',
            image: 'orfeas.jpg',
            youtube: 'https://www.youtube.com/watch?v=4fUozYahKcQ',
            spotify: '',
          },
          {
            name: 'Andreas Kotsarinis',
            speciality: 'Accordeon',
            image: 'andreas.jpg',
            youtube: '',
            spotify: '',
          },
        ],
        description: '',
        eventType: 'Concert',
        video: '',
        time: '15:00',
        doorsOpen: '14:00',
        entrance: '',
        googleLocation:
          'https://www.google.com/maps/place/New+York+Cafe-Restaurant/@38.6722153,21.3571619,17z/data=!3m1!4b1!4m6!3m5!1s0x135e8998997bf2bd:0x754675674d893576!8m2!3d38.6722111!4d21.3597422!16s%2Fg%2F11j8x247hz?entry=ttu',
        locationName: 'New York Pallace',
        locationAddress: 'EO Agriniou Artas, Agrinio 301 31, Greece',
      },
    ],
    supplementary: '',
  },
  {
    id: '1',
    url: 'epirus-unter-den-platanen',
    title: '3-tägiges Festival an Epirotisches Musik',
    description: `<p>Wir laden Euch ein, die reichen und einzigartigen Klänge der Epirus-Musik bei einem
    dreitägigen Festival in Berlin zu erleben.</p>

    <p>Es beginnt mit einem 6-stündigen Workshop, einer Reise durch die Geschichte und Kultur
    der epirotischen Musik. Der Workshop wird von erfahrenen griechischen Musikern
    durchgeführt. Es folgen zwei Tage mit Livemusik von fünf führenden Musikern, die uns aus
    Griechenland besuchen und uns durch ein traditionelles epirotisches Fest führen werden.</p>
    
    <p>Das Fest wird Christos Tzitzimikas mit seiner einzigartigen Stimme präsentieren. Christos hat
    sein zweites Album „Patimata“ mit dem unvergleichlichen Klarinettisten Petroloukas Chalkias
    herausgebracht, und ist außerdem mit anderen legendären Musikern wie Christos Zotos
    aufgetreten. Auf dem Festival wird Christos Tzitzimikas gemeinsam mit Antonis Kaliouris
    (Klarinette), Giannis Poulios (Violine), Panagiotis Tsakos (Gitarre, Laute) und Petros
    Papageorgiou (Schlaginstrumente) auftreten. Christos und seine Parea werden Euch ein
    magisches und unvergessliches Erlebnis bieten und Euch auf einer ganz besonderen Reise
    mit in den Epirus nehmen.</p>
    
    <p>Versäumt nicht einzigartige Möglichkeit, die Magie der epirotischen Musik zu entdecken.</p>
      <br>
      <b>Christos Tzitzimikas und Petroloukas Chalkias</b>
      <p><iframe width="560" height="315" src="https://www.youtube.com/embed/X9OGj5X376U?si=C3LP0yjh8rWw_EIX" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
      
      <b>Petros Papageorgiou und Petroloukas Chalkias</b>
      <p><iframe width="560" height="315" src="https://www.youtube.com/embed/G-h2fOKdyLE?si=NvanRN3ZkDTq9Tld" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p><br>`,
    poster: 'epirus-unter-den-platanen.jpg',
    tip: '',
    isSuggested: false,
    displayArtistList: true,
    days: [
      {
        id: '1',
        startDate: '2024-01-26T14:00:00.349Z',
        endDate: '2024-01-28T21:00:00.349Z',
        title: 'Ein 6-stündiger Workshop + 45-minütigen Konzert',
        poster: '',
        artists: [
          {
            name: 'Christos Tzitzimikas',
            speciality: 'Gesang',
            image: 'tzitzimikas.jpg',
            youtube: 'https://www.youtube.com/@ChristosTzitzimikas',
            spotify: '',
            description: `<h2>Christos Tzitzimikas</h2>
            <p>Christos stammt aus einer Sängerfamilie mit Volkstradition und lernte schon in jungen Jahren den Volksgesang kennen. Seit 1983 beschäftigt er sich mit der Erforschung und Aufführung von Volksliedern aus dem Epirus, aus Mazedonien, Thessalien und Zentralgriechenland. </p>
            
            <p>Er studierte byzantinische Musik am Skalkota-Konservatorium bei Lykourgos Angelopoulos, Gesang bei Lucia Fracea und schloss sein Studium an der T.E.F.A.A. (Fakultät Sport und Sportwissenschaft) Athen ab.</p>
            
            <p>Seine ersten Auftritte hatte er mit der Musikgruppe „TERIREM“. Seitdem hat er mit vielen altbekannten und jungen namhaften Musikern zusammengearbeitet und ist in ganz Griechenland sowie im Ausland auf Konzerten und bei Theateraufführungen aufgetreten. </p>
            
            <p>1993 erschien sein erstes Album mit dem Titel „Zagorisia und Ioanniotika“ (Lieder aus Zagori und Ioannina), herausgegeben von Iamvos, unter Beteiligung von Grigoris Kapsalis an der Klarinette, weiteren zehn namhaften Musikern sowie der freundlichen Beteiligung von „TERIREM“. Giorgos Sygletos ist bei dieser Aufnahme der Toningenieur und Giorgos Papadakis dirigiert das Orchester. Das Album enthält ein Booklet mit vielen Fotografien sowie Informationen auf Griechisch und Englisch. </p>
            
            <p>Ein Lied aus diesem Album wurde im Film „Athen – Konstantinopel“ von Nikos Panagiotopoulos verwendet.</p>
            
            <p>„PATIMATA“ ist der Titel des zweiten Albums, das 2001 von Iamvos veröffentlicht wurde. Es umfasst 16 Lieder mit Bezug auf Pogoni (Gemeinde im Epirus). Mit dabei sind Petroloukas Halkias (Klarinette), Christodoulos Zoumbas (Laute, Musikredakteur) und vier weitere namhafte Musiker. Die Ausgabe erfolgte in Buchform mit ausführlichen historischen und musikwissenschaftlichen Kommentaren auf Griechisch und Englisch sowie Fotomaterial.
            Die beiden Alben erhielten begeisterte Kritiken aus Musikerkreisen sowie von der Presse und werden live in Griechenland und im Ausland präsentiert.</p>

            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/PVZ_Aplp63E?si=-fJsh46h3e6xhdhw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
            <p>2001 beteiligte sich Christos Tzitzimikas an der Aufnahmeedition „Thessalia“, 2015 an der CD von Stamatis Lallas „Aus dem Herzen von Agia Efthymia“, 2019 an der CD von Vassilis Triantis „Portolanos“. 
            Christos unterrichtete traditionellen Gesang an der Fakultät für traditionelle griechische Musik des TEI Arta, an der Fakultät für Musikwissenschaft und Kunst der Universität Mazedonien, im Musikdorf Agios Lavrentios sowie in Seminaren und im Rahmen von Privatunterricht.</p>`,
          },
          {
            name: 'Antonis Kaliouris',
            speciality: 'Klarinette',
            image: 'kalliouris.jpg',
            youtube: 'https://www.youtube.com/@antokaliouris',
            spotify: '',
            description: `<h2>Antonis Kaliouris</h2>
            <p>Antonis Kaliouris wurde in Athen geboren und wuchs dort auf. Seine familiäre Herkunft liegt in Rethymno auf Kreta. Er hat ein Studium an der Fakultät für Biotechnologie der Landwirtschaftlichen Universität Athen abgeschlossen.</p>
            <p>Seine Beschäftigung mit Musik begann im Alter von neun Jahren, als er Laute lernte. Mit 12 Jahren begann er bei dem Lehrer Vangelis Papanastasiou traditionelle Klarinette zu studieren. Später studierte er drei Jahre lang klassische Klarinette am Attischen Konservatorium bei Manousos Ploumidis. Ab 2011 absolvierte er vier Jahre lang traditionellen Klarinettenunterricht bei dem Meister Alexandros Arkadopoulos. Gleichzeitig nahm er Flötenunterricht am Nationalen Konservatorium bei der Lehrerin Danai Kiupuroglou.</p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/_OHX6QXP_Wc?si=PTyaQqksUEbfSHoS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Schon in jungen Jahren nimmt er an kulturellen Veranstaltungen sowie Festivals, Hochzeiten und Tanzveranstaltungen in ganz Griechenland teil. </p>
            <p>Er hat als Musiker an Konzerten, Plattenaufnahmen sowie Radio- und Fernsehsendungen mit verschiedenen Künstlerinnen und Künstlern der populären und traditionellen Szene teilgenommen, wie zum Beispiel: Giorgos Dalaras, Glykeria, Kostas Makedonas, Giannis Kotsiras, Gerasimos Andreatos, Sofia Papazoglou, Babis Tsertos, Lavrentis Machairitsas, Giannis Lempesis, Iota Negka, Miltos Paschalides, Nadia Karagiannis, Kalliopi Vetta, Christos Dandis, Giorgos Merantzas, Erofili, Panagiotis Lalezas, Manos Koutsaνgelidis, Christos Tzitzimikas sowie dem Orchester Estoudiantina Neas Ionias teilgenommen.</p>`,
          },
          {
            name: 'Giannis Poulios',
            speciality: 'Violin',
            image: 'poulios.jpg',
            youtube: 'https://www.youtube.com/@giannispoulios7460',
            spotify: '',
            description: `<h2>Giannis Poulios</h2>
            <p>Giannis Poulios wurde in Bruchsal in Deutschland geboren und wuchs in Katerini auf. </p>
            <p>Er studierte an der Musikschule von Katerini und später an der Fakultät für Volks- und traditionelle Musik in Arta bei den Lehrern Giorgos Psaltis und Makis Baklatzis sowie im Rahmen des Erasmus-Programms für zwei Semester an der Technischen Universität Yildiz in Istanbul mit den Lehrern Özer Özel, Nedim Nalbantoğlu und Murat Sakaryali.</p>
            <p>Heute ist er aktiv in der griechischen Musikszene sowie im Ausland tätig und arbeitet auf Bühnen und bei Musikaufnahmen mit verschiedenen Musikgruppen zusammen, darunter dem „Tasos Poulios Quartett“, den Gruppen „Σummation“ und „Hane“ von Apostolos Sideris, der Gruppe „Iliodromio“, dem Lappino-Projekt von Dimitris Lappas sowie Ali Perrets Gruppe DU.DU in Istanbul.</p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/WUXYTc4RKF4?si=W8Oo7KSTjc0wegug" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Weiterhin hat er bei der Gruppe „Seele und Körper“ mit Mattheos und Kostas Tsachouridis in der Athener Konzerthalle mitgewirkt, mit der Gruppe „Loxandra“ sowie Künstlern wie Eleni Tsaligopoulou, Nena Venetsanou, Haig Yazdjan, Perikles Papapetropoulos, Irini Tornesakis, Areti Ketime, Lisetta Kalimeri, Antonis Apergis, Manos Achalinotopoulos, Fotis Siotas, Nikos Paraoulakis, Kontantinos Plousios und anderen Musikern der griechischen Musikszene zusammengearbeitet.</p>
            <p>Außerdem ist er seit zwei Jahren im pädagogischen Bereich als Vertretungslehrer für traditionelle Violine am Gymnasium für experimentelle Musik in Pallini tätig.</p>`,
          },
          {
            name: 'Panagiotis Tsakos',
            speciality: 'Gitarre, Laute',
            image: 'tsakos.jpg',
            youtube: '',
            spotify: '',
            description: `<h2>Panagiotis Tsakos</h2>
            <p>Panagiotas Tsakos wurde in Nafpaktos geboren und wuchs dort auf. Er studierte an der TEI für Volks- und traditionelle Musik in Arta und spezialisierte sich auf die Volksgitarre.
            Seit 2001 ist er im Bereich der traditionellen Volksmusik und darüber hinaus in Kooperationen und Beteiligungen mit bekannten Namen der Branche aktiv.</p>`,
          },
          {
            name: 'Petros Papageorgiou',
            speciality: 'Schlaginstrumente',
            image: 'papageorgiou.jpg',
            youtube: '',
            spotify: '',
            description: `<h2>Petros Papageorgiou</h2>
            <p>Den ersten Kontakt zur Musik hatte Petros Papageorgiou schon in jungen Jahren mit seiner Aufnahme am öffentlichen Konservatorium von Ioannina. Er studierte vier Jahre lang westliche Musiktheorie und Harmonium. Dann entdeckte und studierte er Schlagzeug mit dem Schwerpunkt auf traditioneller griechischer Musik. Gleichzeitig absolvierte er sein Aufbaustudium an der Fakultät für Musikstudien der Nationalen Kapodistrias-Universität Athen im Programm „Aufführung von Instrumental- und Volksmusik“, Richtung „Aufführung/Interpretation traditioneller Musik“ mit Spezialisierung auf traditionelle Musik/Percussion und setzte seine persönliche Erforschung des Percussion-Bereiches an der Seite von Vangelis Karipis, Ilias Dimanos und Yshai Afterman fort.</p>
            <p>Seit 2003 ist Petros aktives Mitglied der Gruppe En Hordis. Er nahm an Seminaren mit renommierten Percussion-Künstlern wir Misirli Ahmet, Bijan Cemirani, Ziya Tabssian, Mohamed Abdelkader Ibn El Hadj Kacem, David Estefan, Fahrettin Yarkin sowie dem International percussion Meeting (Tunis, 2004) teil. Als Mitglied von En Hordais wurde er mit dem „Prix France Musique des Musiques du Monde“, Babel Med Music / Radio France (Marseille, 2008) ausgezeichnet und wurde für den Sharjah-Preis für arabische Kultur der UNESCO (2006) nominiert. Er hat an mehr als 300 Konzerten in vielen Ländern der Welt bei großen Festivals und berühmten Konzertorten: The Grace Rainey Rogers Auditorium des Metropolitan Museum of Art (New York), Lincoln Center (New York), Maison Symphonic (Montreal), Berliner Philharmonie (Berlin), Salle Pleyel (Paris), Theatre de la Ville (Paris), Institut du Monde Arabe (Paris),Bibliotheca Alexandrina (Ägypten, Carl-Orff-Saal Gasteig (München), Silk Road Arts Festival (Hongkong) Melbourne Recital Centre (Australien), Ten Days on the Island (Tasmanien), Fés Festival (Marokko), Cemal Resit Rey Concert Hall (Istanbul), Onassis Culture Centre (Athen), Cite de la Music (Marseille), Internationales Jerusalemer Oud-Festival (Israel), Athener Konzerthalle, Thessaloniki-Konzerthalle, Les Suds á Arles Festival, Journées Musicales de Carthage (Tunis), Kairoer Opernhaus, Tropenmuseum (Amsterdam), Gibraltar World Music Festival, Rumänisches Kulturinstitut (Bukarest) und in vielen anderen Städten auf der ganzen Welt.</p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/059ZAgJ-lwY?si=dm-eZmD2S8AXS0Sy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Als Gastdozent nahm er an einem Seminar der berühmten Sibelius-Musikakademie in Helsinki teil.</p>
            <p>Er hat an Plattenproduktionen teilgenommen und mit vielen renommierten griechischen und ausländischen Musikern und Musikgruppen zusammengearbeitet, darunter: Hellenic National Radio Television Orchestra, Ensemble Constantinople, Anton Pan, Theodossi Spassov, Omar Faruk Tekbilek, Kudsi Ergüner, Melihat Gülses, Mehmet Bitmez, Misirli Ahmet, Zohar Fresco, Derya Turkan, Petroloukas Halkias, Chronis  Aidonidis, Solon Lekkas, Nikos Saragoudas, Jasemi Saradoudas, Maria Farantouri, Grigoris Kapsalis, Nikos Filippidis, Haig Yazdjian und viele andere.</p>`,
          },
        ],
        description: `<p>
            Ein 6-stündiger lebendiger Workshop, der der Musik des Epirus gewidmet ist und von erfahrenen griechischen Musikern durchgeführt wird. Anschließend folgt ein 45-minütiges Konzert mit dem Titel „Kidói mané“ (albanisch und türkisch: Lied).
          </p>
          <p>
          In diesem Workshop kommen Interessierte in direkten Kontakt mit den traditionellen Instrumenten sowie den Liedern des Epirus. Ziel ist es, das Verständnis für den natürlichen Charakter und die musikalischen Möglichkeiten der Instrumente und der Stimme zu entwickeln. 
          </p><p>
          Dabei werden Lieder aus dem Epirus präsentiert. Es wird möglich sein, folgende Themen zu besprechen:<br>
            - die Bedeutung traditioneller Musik<br>
            - die Beziehung und die Unterschiede zu anderen Musikrichtungen<br>
            - die musikwissenschaftliche Identität und die musikalischen Möglichkeiten der grundlegenden traditionellen Instrumente<br>
            - die grundlegenden musikalischen traditionellen Tonleitern<br>
            - die Art und Weise der instrumentalen und gesanglichen Darbietung traditioneller Lieder
          </p>
          <p>
          „Kidói mané“! – Ein Musikprogramm, bestehend aus Musik und Liedern des Epirus, dem weiteren Gebiet von Pindos und Xiromero mit dem gemeinsamen Merkmal ihrer orientalischen Herkunft, Liedern aus der Zeit des Osmanischen Reiches, aber auch aus neuerer Zeit, aus der Zeit von Café Aman, arrangiert von Volksmusikanten; Liedern, deren Erschaffung mit der Zeit verloren geht, die aber immer noch ihren Platz auf Feiern und in den Diskographien haben. Einige davon kennen wir von der Doppel-LP „Zagorisia und Ioanniotika“ kennen, der ersten Plattenaufnahme von Christos Tzitzimikas (erschienen bei Iamvos 1993).
          </p>
          <p><b class="warning">Wichtige Information:</b> Damit der Workshop stattfinden kann, benötigen wir eine Mindestteilnehmerzahl von 20 Personen. Falls diese Zahl nicht erreicht wird, wird der Workshop abgesagt und Sie erhalten eine vollständige Rückerstattung des Ticketpreises. Im Falle einer Absage werden Sie auch per E-Mail benachrichtigt.</p>
        `,
        eventType: 'Workshop & Konzert',
        video: '',
        time: 'Workshop: 15:00 | Konzert: 21:00',
        doorsOpen: '15:00',
        entrance: 'Eintritt frei',
        googleLocation:
          'https://www.google.com/maps/place/BAVUL+-+Kunst+%26+Kultur+Caf%C3%A9/@52.5093458,13.4121714,15z/data=!4m6!3m5!1s0x47a84f3c13e1227b:0x259905d739f1b5c2!8m2!3d52.5093458!4d13.4121714!16s%2Fg%2F11fm2m1wvj?entry=ttu',
        locationName: 'Bavul',
        locationAddress: 'Annenstraße 13, 10179 Berlin',
      },
      {
        id: '2',
        startDate: '2024-01-27T20:00:00.349Z',
        endDate: '2024-01-28T05:00:00.349Z',
        title: 'Live Konzert mit Christos Tzitzimikas und seine Freunde',
        poster: '',
        artists: [
          {
            name: 'Christos Tzitzimikas',
            speciality: 'Gesang',
            image: 'tzitzimikas.jpg',
            youtube: 'https://www.youtube.com/@ChristosTzitzimikas',
            spotify: '',
            description: `<h2>Christos Tzitzimikas</h2>
            <p>Christos stammt aus einer Sängerfamilie mit Volkstradition und lernte schon in jungen Jahren den Volksgesang kennen. Seit 1983 beschäftigt er sich mit der Erforschung und Aufführung von Volksliedern aus dem Epirus, aus Mazedonien, Thessalien und Zentralgriechenland. </p>
            
            <p>Er studierte byzantinische Musik am Skalkota-Konservatorium bei Lykourgos Angelopoulos, Gesang bei Lucia Fracea und schloss sein Studium an der T.E.F.A.A. (Fakultät Sport und Sportwissenschaft) Athen ab.</p>
            
            <p>Seine ersten Auftritte hatte er mit der Musikgruppe „TERIREM“. Seitdem hat er mit vielen altbekannten und jungen namhaften Musikern zusammengearbeitet und ist in ganz Griechenland sowie im Ausland auf Konzerten und bei Theateraufführungen aufgetreten. </p>
            
            <p>1993 erschien sein erstes Album mit dem Titel „Zagorisia und Ioanniotika“ (Lieder aus Zagori und Ioannina), herausgegeben von Iamvos, unter Beteiligung von Grigoris Kapsalis an der Klarinette, weiteren zehn namhaften Musikern sowie der freundlichen Beteiligung von „TERIREM“. Giorgos Sygletos ist bei dieser Aufnahme der Toningenieur und Giorgos Papadakis dirigiert das Orchester. Das Album enthält ein Booklet mit vielen Fotografien sowie Informationen auf Griechisch und Englisch. </p>
            
            <p>Ein Lied aus diesem Album wurde im Film „Athen – Konstantinopel“ von Nikos Panagiotopoulos verwendet.</p>
            
            <p>„PATIMATA“ ist der Titel des zweiten Albums, das 2001 von Iamvos veröffentlicht wurde. Es umfasst 16 Lieder mit Bezug auf Pogoni (Gemeinde im Epirus). Mit dabei sind Petroloukas Halkias (Klarinette), Christodoulos Zoumbas (Laute, Musikredakteur) und vier weitere namhafte Musiker. Die Ausgabe erfolgte in Buchform mit ausführlichen historischen und musikwissenschaftlichen Kommentaren auf Griechisch und Englisch sowie Fotomaterial.
            Die beiden Alben erhielten begeisterte Kritiken aus Musikerkreisen sowie von der Presse und werden live in Griechenland und im Ausland präsentiert.</p>

            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/PVZ_Aplp63E?si=-fJsh46h3e6xhdhw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
            <p>2001 beteiligte sich Christos Tzitzimikas an der Aufnahmeedition „Thessalia“, 2015 an der CD von Stamatis Lallas „Aus dem Herzen von Agia Efthymia“, 2019 an der CD von Vassilis Triantis „Portolanos“. 
            Christos unterrichtete traditionellen Gesang an der Fakultät für traditionelle griechische Musik des TEI Arta, an der Fakultät für Musikwissenschaft und Kunst der Universität Mazedonien, im Musikdorf Agios Lavrentios sowie in Seminaren und im Rahmen von Privatunterricht.</p>`,
          },
          {
            name: 'Antonis Kaliouris',
            speciality: 'Klarinette',
            image: 'kalliouris.jpg',
            youtube: 'https://www.youtube.com/@antokaliouris',
            spotify: '',
            description: `<h2>Antonis Kaliouris</h2>
            <p>Antonis Kaliouris wurde in Athen geboren und wuchs dort auf. Seine familiäre Herkunft liegt in Rethymno auf Kreta. Er hat ein Studium an der Fakultät für Biotechnologie der Landwirtschaftlichen Universität Athen abgeschlossen.</p>
            <p>Seine Beschäftigung mit Musik begann im Alter von neun Jahren, als er Laute lernte. Mit 12 Jahren begann er bei dem Lehrer Vangelis Papanastasiou traditionelle Klarinette zu studieren. Später studierte er drei Jahre lang klassische Klarinette am Attischen Konservatorium bei Manousos Ploumidis. Ab 2011 absolvierte er vier Jahre lang traditionellen Klarinettenunterricht bei dem Meister Alexandros Arkadopoulos. Gleichzeitig nahm er Flötenunterricht am Nationalen Konservatorium bei der Lehrerin Danai Kiupuroglou.</p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/_OHX6QXP_Wc?si=PTyaQqksUEbfSHoS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Schon in jungen Jahren nimmt er an kulturellen Veranstaltungen sowie Festivals, Hochzeiten und Tanzveranstaltungen in ganz Griechenland teil. </p>
            <p>Er hat als Musiker an Konzerten, Plattenaufnahmen sowie Radio- und Fernsehsendungen mit verschiedenen Künstlerinnen und Künstlern der populären und traditionellen Szene teilgenommen, wie zum Beispiel: Giorgos Dalaras, Glykeria, Kostas Makedonas, Giannis Kotsiras, Gerasimos Andreatos, Sofia Papazoglou, Babis Tsertos, Lavrentis Machairitsas, Giannis Lempesis, Iota Negka, Miltos Paschalides, Nadia Karagiannis, Kalliopi Vetta, Christos Dandis, Giorgos Merantzas, Erofili, Panagiotis Lalezas, Manos Koutsaνgelidis, Christos Tzitzimikas sowie dem Orchester Estoudiantina Neas Ionias teilgenommen.</p>`,
          },
          {
            name: 'Giannis Poulios',
            speciality: 'Violin',
            image: 'poulios.jpg',
            youtube: 'https://www.youtube.com/@giannispoulios7460',
            spotify: '',
            description: `<h2>Giannis Poulios</h2>
            <p>Giannis Poulios wurde in Bruchsal in Deutschland geboren und wuchs in Katerini auf. </p>
            <p>Er studierte an der Musikschule von Katerini und später an der Fakultät für Volks- und traditionelle Musik in Arta bei den Lehrern Giorgos Psaltis und Makis Baklatzis sowie im Rahmen des Erasmus-Programms für zwei Semester an der Technischen Universität Yildiz in Istanbul mit den Lehrern Özer Özel, Nedim Nalbantoğlu und Murat Sakaryali.</p>
            <p>Heute ist er aktiv in der griechischen Musikszene sowie im Ausland tätig und arbeitet auf Bühnen und bei Musikaufnahmen mit verschiedenen Musikgruppen zusammen, darunter dem „Tasos Poulios Quartett“, den Gruppen „Σummation“ und „Hane“ von Apostolos Sideris, der Gruppe „Iliodromio“, dem Lappino-Projekt von Dimitris Lappas sowie Ali Perrets Gruppe DU.DU in Istanbul.</p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/WUXYTc4RKF4?si=W8Oo7KSTjc0wegug" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Weiterhin hat er bei der Gruppe „Seele und Körper“ mit Mattheos und Kostas Tsachouridis in der Athener Konzerthalle mitgewirkt, mit der Gruppe „Loxandra“ sowie Künstlern wie Eleni Tsaligopoulou, Nena Venetsanou, Haig Yazdjan, Perikles Papapetropoulos, Irini Tornesakis, Areti Ketime, Lisetta Kalimeri, Antonis Apergis, Manos Achalinotopoulos, Fotis Siotas, Nikos Paraoulakis, Kontantinos Plousios und anderen Musikern der griechischen Musikszene zusammengearbeitet.</p>
            <p>Außerdem ist er seit zwei Jahren im pädagogischen Bereich als Vertretungslehrer für traditionelle Violine am Gymnasium für experimentelle Musik in Pallini tätig.</p>`,
          },
          {
            name: 'Panagiotis Tsakos',
            speciality: 'Gitarre, Laute',
            image: 'tsakos.jpg',
            youtube: '',
            spotify: '',
            description: `<h2>Panagiotis Tsakos</h2>
            <p>Panagiotas Tsakos wurde in Nafpaktos geboren und wuchs dort auf. Er studierte an der TEI für Volks- und traditionelle Musik in Arta und spezialisierte sich auf die Volksgitarre.
            Seit 2001 ist er im Bereich der traditionellen Volksmusik und darüber hinaus in Kooperationen und Beteiligungen mit bekannten Namen der Branche aktiv.</p>`,
          },
          {
            name: 'Petros Papageorgiou',
            speciality: 'Schlaginstrumente',
            image: 'papageorgiou.jpg',
            youtube: '',
            spotify: '',
            description: `<h2>Petros Papageorgiou</h2>
            <p>Den ersten Kontakt zur Musik hatte Petros Papageorgiou schon in jungen Jahren mit seiner Aufnahme am öffentlichen Konservatorium von Ioannina. Er studierte vier Jahre lang westliche Musiktheorie und Harmonium. Dann entdeckte und studierte er Schlagzeug mit dem Schwerpunkt auf traditioneller griechischer Musik. Gleichzeitig absolvierte er sein Aufbaustudium an der Fakultät für Musikstudien der Nationalen Kapodistrias-Universität Athen im Programm „Aufführung von Instrumental- und Volksmusik“, Richtung „Aufführung/Interpretation traditioneller Musik“ mit Spezialisierung auf traditionelle Musik/Percussion und setzte seine persönliche Erforschung des Percussion-Bereiches an der Seite von Vangelis Karipis, Ilias Dimanos und Yshai Afterman fort.</p>
            <p>Seit 2003 ist Petros aktives Mitglied der Gruppe En Hordis. Er nahm an Seminaren mit renommierten Percussion-Künstlern wir Misirli Ahmet, Bijan Cemirani, Ziya Tabssian, Mohamed Abdelkader Ibn El Hadj Kacem, David Estefan, Fahrettin Yarkin sowie dem International percussion Meeting (Tunis, 2004) teil. Als Mitglied von En Hordais wurde er mit dem „Prix France Musique des Musiques du Monde“, Babel Med Music / Radio France (Marseille, 2008) ausgezeichnet und wurde für den Sharjah-Preis für arabische Kultur der UNESCO (2006) nominiert. Er hat an mehr als 300 Konzerten in vielen Ländern der Welt bei großen Festivals und berühmten Konzertorten: The Grace Rainey Rogers Auditorium des Metropolitan Museum of Art (New York), Lincoln Center (New York), Maison Symphonic (Montreal), Berliner Philharmonie (Berlin), Salle Pleyel (Paris), Theatre de la Ville (Paris), Institut du Monde Arabe (Paris),Bibliotheca Alexandrina (Ägypten, Carl-Orff-Saal Gasteig (München), Silk Road Arts Festival (Hongkong) Melbourne Recital Centre (Australien), Ten Days on the Island (Tasmanien), Fés Festival (Marokko), Cemal Resit Rey Concert Hall (Istanbul), Onassis Culture Centre (Athen), Cite de la Music (Marseille), Internationales Jerusalemer Oud-Festival (Israel), Athener Konzerthalle, Thessaloniki-Konzerthalle, Les Suds á Arles Festival, Journées Musicales de Carthage (Tunis), Kairoer Opernhaus, Tropenmuseum (Amsterdam), Gibraltar World Music Festival, Rumänisches Kulturinstitut (Bukarest) und in vielen anderen Städten auf der ganzen Welt.</p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/059ZAgJ-lwY?si=dm-eZmD2S8AXS0Sy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Als Gastdozent nahm er an einem Seminar der berühmten Sibelius-Musikakademie in Helsinki teil.</p>
            <p>Er hat an Plattenproduktionen teilgenommen und mit vielen renommierten griechischen und ausländischen Musikern und Musikgruppen zusammengearbeitet, darunter: Hellenic National Radio Television Orchestra, Ensemble Constantinople, Anton Pan, Theodossi Spassov, Omar Faruk Tekbilek, Kudsi Ergüner, Melihat Gülses, Mehmet Bitmez, Misirli Ahmet, Zohar Fresco, Derya Turkan, Petroloukas Halkias, Chronis  Aidonidis, Solon Lekkas, Nikos Saragoudas, Jasemi Saradoudas, Maria Farantouri, Grigoris Kapsalis, Nikos Filippidis, Haig Yazdjian und viele andere.</p>`,
          },
        ],
        description: `<p>
        An den nächsten beiden Tagen laden uns Christos Tzitzimikas und seine Freunde an zwei Abenden zur Begegnung mit den tiefsten Traditionen des Epirus ein. Der bekannte Sänger veranstaltet ein echtes traditionelles Fest und hebt den Reichtum der griechischen Tradition hervor. Ein authentisches epirotisches Fest in Berlin!
    </p>
    `,
        eventType: 'Konzert',
        video: '',
        time: '21:00',
        doorsOpen: '20:00',
        entrance: '',
        googleLocation:
          'https://www.google.com/maps/place/Biergarten+Jockel/@52.4919277,13.4385616,15z/data=!4m6!3m5!1s0x47a84fad83ee12cf:0x32222854a7e82f96!8m2!3d52.4919277!4d13.4385616!16s%2Fg%2F1v16pb3n?entry=ttu',
        locationName: 'Eventhall Jockel',
        locationAddress: 'Ratiborstraße 14c, 10999 Berlin',
      },
      {
        id: '3',
        startDate: '2024-01-28T17:00:00.349Z',
        endDate: '2024-01-28T22:00:00.349Z',
        title: 'Live Konzert mit Christos Tzitzimikas und seine Freunde',
        poster: '',
        artists: [
          {
            name: 'Christos Tzitzimikas',
            speciality: 'Gesang',
            image: 'tzitzimikas.jpg',
            youtube: 'https://www.youtube.com/@ChristosTzitzimikas',
            spotify: '',
            description: `<h2>Christos Tzitzimikas</h2>
            <p>Christos stammt aus einer Sängerfamilie mit Volkstradition und lernte schon in jungen Jahren den Volksgesang kennen. Seit 1983 beschäftigt er sich mit der Erforschung und Aufführung von Volksliedern aus dem Epirus, aus Mazedonien, Thessalien und Zentralgriechenland. </p>
            
            <p>Er studierte byzantinische Musik am Skalkota-Konservatorium bei Lykourgos Angelopoulos, Gesang bei Lucia Fracea und schloss sein Studium an der T.E.F.A.A. (Fakultät Sport und Sportwissenschaft) Athen ab.</p>
            
            <p>Seine ersten Auftritte hatte er mit der Musikgruppe „TERIREM“. Seitdem hat er mit vielen altbekannten und jungen namhaften Musikern zusammengearbeitet und ist in ganz Griechenland sowie im Ausland auf Konzerten und bei Theateraufführungen aufgetreten. </p>
            
            <p>1993 erschien sein erstes Album mit dem Titel „Zagorisia und Ioanniotika“ (Lieder aus Zagori und Ioannina), herausgegeben von Iamvos, unter Beteiligung von Grigoris Kapsalis an der Klarinette, weiteren zehn namhaften Musikern sowie der freundlichen Beteiligung von „TERIREM“. Giorgos Sygletos ist bei dieser Aufnahme der Toningenieur und Giorgos Papadakis dirigiert das Orchester. Das Album enthält ein Booklet mit vielen Fotografien sowie Informationen auf Griechisch und Englisch. </p>
            
            <p>Ein Lied aus diesem Album wurde im Film „Athen – Konstantinopel“ von Nikos Panagiotopoulos verwendet.</p>
            
            <p>„PATIMATA“ ist der Titel des zweiten Albums, das 2001 von Iamvos veröffentlicht wurde. Es umfasst 16 Lieder mit Bezug auf Pogoni (Gemeinde im Epirus). Mit dabei sind Petroloukas Halkias (Klarinette), Christodoulos Zoumbas (Laute, Musikredakteur) und vier weitere namhafte Musiker. Die Ausgabe erfolgte in Buchform mit ausführlichen historischen und musikwissenschaftlichen Kommentaren auf Griechisch und Englisch sowie Fotomaterial.
            Die beiden Alben erhielten begeisterte Kritiken aus Musikerkreisen sowie von der Presse und werden live in Griechenland und im Ausland präsentiert.</p>

            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/PVZ_Aplp63E?si=-fJsh46h3e6xhdhw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            
            <p>2001 beteiligte sich Christos Tzitzimikas an der Aufnahmeedition „Thessalia“, 2015 an der CD von Stamatis Lallas „Aus dem Herzen von Agia Efthymia“, 2019 an der CD von Vassilis Triantis „Portolanos“. 
            Christos unterrichtete traditionellen Gesang an der Fakultät für traditionelle griechische Musik des TEI Arta, an der Fakultät für Musikwissenschaft und Kunst der Universität Mazedonien, im Musikdorf Agios Lavrentios sowie in Seminaren und im Rahmen von Privatunterricht.</p>`,
          },
          {
            name: 'Antonis Kaliouris',
            speciality: 'Klarinette',
            image: 'kalliouris.jpg',
            youtube: 'https://www.youtube.com/@antokaliouris',
            spotify: '',
            description: `<h2>Antonis Kaliouris</h2>
            <p>Antonis Kaliouris wurde in Athen geboren und wuchs dort auf. Seine familiäre Herkunft liegt in Rethymno auf Kreta. Er hat ein Studium an der Fakultät für Biotechnologie der Landwirtschaftlichen Universität Athen abgeschlossen.</p>
            <p>Seine Beschäftigung mit Musik begann im Alter von neun Jahren, als er Laute lernte. Mit 12 Jahren begann er bei dem Lehrer Vangelis Papanastasiou traditionelle Klarinette zu studieren. Später studierte er drei Jahre lang klassische Klarinette am Attischen Konservatorium bei Manousos Ploumidis. Ab 2011 absolvierte er vier Jahre lang traditionellen Klarinettenunterricht bei dem Meister Alexandros Arkadopoulos. Gleichzeitig nahm er Flötenunterricht am Nationalen Konservatorium bei der Lehrerin Danai Kiupuroglou.</p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/_OHX6QXP_Wc?si=PTyaQqksUEbfSHoS" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Schon in jungen Jahren nimmt er an kulturellen Veranstaltungen sowie Festivals, Hochzeiten und Tanzveranstaltungen in ganz Griechenland teil. </p>
            <p>Er hat als Musiker an Konzerten, Plattenaufnahmen sowie Radio- und Fernsehsendungen mit verschiedenen Künstlerinnen und Künstlern der populären und traditionellen Szene teilgenommen, wie zum Beispiel: Giorgos Dalaras, Glykeria, Kostas Makedonas, Giannis Kotsiras, Gerasimos Andreatos, Sofia Papazoglou, Babis Tsertos, Lavrentis Machairitsas, Giannis Lempesis, Iota Negka, Miltos Paschalides, Nadia Karagiannis, Kalliopi Vetta, Christos Dandis, Giorgos Merantzas, Erofili, Panagiotis Lalezas, Manos Koutsaνgelidis, Christos Tzitzimikas sowie dem Orchester Estoudiantina Neas Ionias teilgenommen.</p>`,
          },
          {
            name: 'Giannis Poulios',
            speciality: 'Violin',
            image: 'poulios.jpg',
            youtube: 'https://www.youtube.com/@giannispoulios7460',
            spotify: '',
            description: `<h2>Giannis Poulios</h2>
            <p>Giannis Poulios wurde in Bruchsal in Deutschland geboren und wuchs in Katerini auf. </p>
            <p>Er studierte an der Musikschule von Katerini und später an der Fakultät für Volks- und traditionelle Musik in Arta bei den Lehrern Giorgos Psaltis und Makis Baklatzis sowie im Rahmen des Erasmus-Programms für zwei Semester an der Technischen Universität Yildiz in Istanbul mit den Lehrern Özer Özel, Nedim Nalbantoğlu und Murat Sakaryali.</p>
            <p>Heute ist er aktiv in der griechischen Musikszene sowie im Ausland tätig und arbeitet auf Bühnen und bei Musikaufnahmen mit verschiedenen Musikgruppen zusammen, darunter dem „Tasos Poulios Quartett“, den Gruppen „Σummation“ und „Hane“ von Apostolos Sideris, der Gruppe „Iliodromio“, dem Lappino-Projekt von Dimitris Lappas sowie Ali Perrets Gruppe DU.DU in Istanbul.</p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/WUXYTc4RKF4?si=W8Oo7KSTjc0wegug" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Weiterhin hat er bei der Gruppe „Seele und Körper“ mit Mattheos und Kostas Tsachouridis in der Athener Konzerthalle mitgewirkt, mit der Gruppe „Loxandra“ sowie Künstlern wie Eleni Tsaligopoulou, Nena Venetsanou, Haig Yazdjan, Perikles Papapetropoulos, Irini Tornesakis, Areti Ketime, Lisetta Kalimeri, Antonis Apergis, Manos Achalinotopoulos, Fotis Siotas, Nikos Paraoulakis, Kontantinos Plousios und anderen Musikern der griechischen Musikszene zusammengearbeitet.</p>
            <p>Außerdem ist er seit zwei Jahren im pädagogischen Bereich als Vertretungslehrer für traditionelle Violine am Gymnasium für experimentelle Musik in Pallini tätig.</p>`,
          },
          {
            name: 'Panagiotis Tsakos',
            speciality: 'Gitarre, Laute',
            image: 'tsakos.jpg',
            youtube: '',
            spotify: '',
            description: `<h2>Panagiotis Tsakos</h2>
            <p>Panagiotas Tsakos wurde in Nafpaktos geboren und wuchs dort auf. Er studierte an der TEI für Volks- und traditionelle Musik in Arta und spezialisierte sich auf die Volksgitarre.
            Seit 2001 ist er im Bereich der traditionellen Volksmusik und darüber hinaus in Kooperationen und Beteiligungen mit bekannten Namen der Branche aktiv.</p>`,
          },
          {
            name: 'Petros Papageorgiou',
            speciality: 'Schlaginstrumente',
            image: 'papageorgiou.jpg',
            youtube: '',
            spotify: '',
            description: `<h2>Petros Papageorgiou</h2>
            <p>Den ersten Kontakt zur Musik hatte Petros Papageorgiou schon in jungen Jahren mit seiner Aufnahme am öffentlichen Konservatorium von Ioannina. Er studierte vier Jahre lang westliche Musiktheorie und Harmonium. Dann entdeckte und studierte er Schlagzeug mit dem Schwerpunkt auf traditioneller griechischer Musik. Gleichzeitig absolvierte er sein Aufbaustudium an der Fakultät für Musikstudien der Nationalen Kapodistrias-Universität Athen im Programm „Aufführung von Instrumental- und Volksmusik“, Richtung „Aufführung/Interpretation traditioneller Musik“ mit Spezialisierung auf traditionelle Musik/Percussion und setzte seine persönliche Erforschung des Percussion-Bereiches an der Seite von Vangelis Karipis, Ilias Dimanos und Yshai Afterman fort.</p>
            <p>Seit 2003 ist Petros aktives Mitglied der Gruppe En Hordis. Er nahm an Seminaren mit renommierten Percussion-Künstlern wir Misirli Ahmet, Bijan Cemirani, Ziya Tabssian, Mohamed Abdelkader Ibn El Hadj Kacem, David Estefan, Fahrettin Yarkin sowie dem International percussion Meeting (Tunis, 2004) teil. Als Mitglied von En Hordais wurde er mit dem „Prix France Musique des Musiques du Monde“, Babel Med Music / Radio France (Marseille, 2008) ausgezeichnet und wurde für den Sharjah-Preis für arabische Kultur der UNESCO (2006) nominiert. Er hat an mehr als 300 Konzerten in vielen Ländern der Welt bei großen Festivals und berühmten Konzertorten: The Grace Rainey Rogers Auditorium des Metropolitan Museum of Art (New York), Lincoln Center (New York), Maison Symphonic (Montreal), Berliner Philharmonie (Berlin), Salle Pleyel (Paris), Theatre de la Ville (Paris), Institut du Monde Arabe (Paris),Bibliotheca Alexandrina (Ägypten, Carl-Orff-Saal Gasteig (München), Silk Road Arts Festival (Hongkong) Melbourne Recital Centre (Australien), Ten Days on the Island (Tasmanien), Fés Festival (Marokko), Cemal Resit Rey Concert Hall (Istanbul), Onassis Culture Centre (Athen), Cite de la Music (Marseille), Internationales Jerusalemer Oud-Festival (Israel), Athener Konzerthalle, Thessaloniki-Konzerthalle, Les Suds á Arles Festival, Journées Musicales de Carthage (Tunis), Kairoer Opernhaus, Tropenmuseum (Amsterdam), Gibraltar World Music Festival, Rumänisches Kulturinstitut (Bukarest) und in vielen anderen Städten auf der ganzen Welt.</p>
            <p><iframe width="560" height="315" src="https://www.youtube.com/embed/059ZAgJ-lwY?si=dm-eZmD2S8AXS0Sy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
            <p>Als Gastdozent nahm er an einem Seminar der berühmten Sibelius-Musikakademie in Helsinki teil.</p>
            <p>Er hat an Plattenproduktionen teilgenommen und mit vielen renommierten griechischen und ausländischen Musikern und Musikgruppen zusammengearbeitet, darunter: Hellenic National Radio Television Orchestra, Ensemble Constantinople, Anton Pan, Theodossi Spassov, Omar Faruk Tekbilek, Kudsi Ergüner, Melihat Gülses, Mehmet Bitmez, Misirli Ahmet, Zohar Fresco, Derya Turkan, Petroloukas Halkias, Chronis  Aidonidis, Solon Lekkas, Nikos Saragoudas, Jasemi Saradoudas, Maria Farantouri, Grigoris Kapsalis, Nikos Filippidis, Haig Yazdjian und viele andere.</p>`,
          },
        ],
        description: `<p>
        An den nächsten beiden Tagen laden uns Christos Tzitzimikas und seine Freunde an zwei Abenden zur Begegnung mit den tiefsten Traditionen des Epirus ein. Der bekannte Sänger veranstaltet ein echtes traditionelles Fest und hebt den Reichtum der griechischen Tradition hervor. Ein authentisches epirotisches Fest in Berlin!
    </p>
    `,
        eventType: 'Concert',
        video: '',
        time: '18:00',
        doorsOpen: '17:00',
        entrance: '',
        googleLocation:
          'https://www.google.com/maps/place/Biergarten+Jockel/@52.4919277,13.4385616,15z/data=!4m6!3m5!1s0x47a84fad83ee12cf:0x32222854a7e82f96!8m2!3d52.4919277!4d13.4385616!16s%2Fg%2F1v16pb3n?entry=ttu',
        locationName: 'Eventhall Jockel',
        locationAddress: 'Ratiborstraße 14c, 10999 Berlin',
      },
    ],
    supplementary: `<h2>„Tod, die Fremde, Bitterkeit und Waisentum“ – Das ist das Lied vom Epirus</h2>
    <p>
    Der Epirus ist die geografische Region an der Nordwestspitze Griechenlands. Der Name leitet sich vom Adjektiv „apeiros“ (άπειρος – aus dem Altgriechischen: Kontinent, Festland; auch: Ufer) ab. Es ist eine überwiegend gebirgige Region und das Tiefland liegt hauptsächlich an den Mündungen der Flüsse. 
    </p>
    <p>
    Die Musiktradition des Epirus ist einer der größten kulturellen Schätze Griechenlands. In den abgelegenen Dörfern des Epirus wurde ein unverwechselbarer Klang und ein einzigartiger Rhythmus gepflegt und bewahrt. Die lokale Musik und Lieder haben eine ursprüngliche Reinheit, eine heilende Kraft. Die Musikkultur des Epirus ist seit Jahrtausenden unverändert und unverfälscht durch ausländische Einflüsse geblieben. 
    </p>
    <p>
    Gesang und Tanz des Epirus sind ein kollektiver Akt der Erinnerung, des Bekenntnisses, der Leichtigkeit und der Volksweisheit, eine Reise an den Ausgangspunkt, den Ursprung und die Entwicklung des Lebens und der Emotionen, die tief im historischen Bewusstsein der Menschen des Epirus verwurzelt sind, und die sich von Ätolien-Arkananien bis nach Südalbanien erstrecken.
    </p>
    <p>
    Die Musik des Epirus unterscheidet sich von den anderen Regionen Griechenlands durch die harmonische und melodische Farbe, die sie umgibt: Die Melodielinien sind kurz, der Klang traurig, selbst die Lieder mit fröhlichem oder lustigem Inhalt klingen „schwer“. Die wilde Strenge der Berglandschaft spiegelt sich zwangsläufig in der Vermeidung jeglichen unnötigen Überschwangs wider, selbst wenn der Künstler auf diese charakteristischen Ornamente und Verzierungen zurückgreift.
    </p>
    <p>
    Der Inhalt der Lieder variiert. Es gibt Bezüge zu historischen und heroischen Ereignissen; es gibt sentimentale Lieder, Hochzeitslieder, Hirtenlieder, Lieder der Reisenden sowie viele Lieder mit Bezügen zur Fremde. Nur das Kriegslied war nicht im ganzen Epirus verbreitet, vor allem weil im Epirus nicht überall gleichermaßen häufig gekämpft wurde. So finden wir Kriegslieder vor allem in Thesprotien und speziell in der Gegend rund um Souli.
    </p>
    <p>
    Die Klarinette ist das führende Musikinstrument. Weitere Instrumente der epirotischen Musik sind die Geige, die Landlaute, das Tamburin, die Flöte, die Tzamara (eine Flötenart), sowie das Santuri.
    </p>
    <p>
    Bei den traditionellen epirotischen Festen gibt es ein Ritual: Das erste Lied ist immer ein Klagelied, entweder für Tote oder für Menschen, die in der Fremde leben. Das Konzept der Fremde hatte im Epirus Züge des Todes. Anschließend folgt normalerweise der „Skaros“ (ein spezielles Hirtenlied, das den Beginn der Beweidung in der Nacht oder den frühen Morgenstunden beschreibt; instrumental werden die typischen Geräusche des Hirtenlebens, wie das Blöken der Schafe, das Winseln der Hunde und das Läuten der Glöckchen nachempfunden). Danach gibt es Musik mit einer Vielzahl von Klängen, Melodien und Texten, ein Mosaik aus Freude, Leid, Nostalgie, der täglichen Last sowie der zeitweiligen Erlösung davon. Das Fest endet immer mit Abschiedsliedern. Die Volkslieder existierten nur mündlich. Es wurden weder die Musik, noch die Texte geschrieben. Es waren Geschichten, die sich von Mund zu Mund verbreiteten.
    </p>
    `,
  },
  {
    id: '2',
    url: 'bando-and-friends2',
    title: 'Bando & Friends 2',
    description: `<p>Die zweite Ausgabe von BANDO & friends und gleichzeitig die Party zum 25. Geburtstag von BANDO!!</p>
    <p>Das ganze wird im ehemaligen BANDO-Keller (heute Teil des Jugendhauses Königstadt) in Prenzlauer Berg entsprechend zelebriert werden.</p>
    <p>Kommt & bringt eure Freunde mit, wir wollen mit euch feiern!!</p>
    `,
    poster: 'bando-friends.jpg',
    tip: '',
    isSuggested: true,
    days: [
      {
        id: '1',
        startDate: '2023-12-01T19:00:00.349Z',
        endDate: '2023-12-02T05:00:00.349Z',
        title: '',
        poster: '',
        artists: [
          {
            name: 'Gehirnfrost',
            speciality: 'hardtech/stoner/metal/psychedelic',
            image: '',
            youtube: 'https://www.youtube.com/watch?v=8EfZsGhlbf8&t=1s',
            spotify: '',
          },
          {
            name: 'Local support',
            speciality: 'metal/punk/hardcore',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'BANDO',
            speciality: 'Hardcore-Schlagzeug',
            image: '',
            youtube: 'https://www.youtube.com/watch?v=6a0B_g5oGt0',
            spotify: '',
          },
        ],
        description: '',
        eventType: 'Concert',
        video: '',
        time: '20:00',
        doorsOpen: '19:00',
        entrance: '',
        googleLocation:
          'https://www.google.com/maps/place/BANDO+Berlin/@52.5417735,13.3859352,14z/data=!3m1!5s0x47a851e24f3df225:0x9c79e48257493088!4m10!1m2!2m1!1sBANDO+Berlin!3m6!1s0x47a84e1d481a733f:0xc5c1ccb3d36c4565!8m2!3d52.5306014!4d13.4134281!15sCgxCQU5ETyBCZXJsaW6SAQRiYW5k4AEA!16s%2Fg%2F11fkf7x9l7?entry=ttu',
        locationName: 'Jugendhaus Königstadt',
        locationAddress: 'Saarbrücker Straße 23 (U-Bahn Senefelder Platz)',
      },
    ],
    supplementary: '',
  },
  {
    id: '3',
    url: 'glenti-advent-dance-party-ljuti-hora',
    title: 'Glenti – Advents-Tanzhaus mit Ljuti Hora (Berlin)',
    description: `<p>Es wird viel griechische und andere Balkanmusik geben. Die Musiker sind in bester Spiellaune und freuen sich darauf, euch das Tanzbein schwingen zu sehen!!</p>
    `,
    poster: 'LjutiHora-2-12-23.jpg',
    tip: '',
    types: ['suggested', 'participating'],
    isSuggested: true,
    days: [
      {
        id: '1',
        startDate: '2023-12-02T19:00:00.349Z',
        endDate: '2023-12-03T05:00:00.349Z',
        title: '',
        poster: '',
        artists: [
          {
            name: 'Mate Gaal',
            speciality: 'Klarinette, Flöten, Gesang',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Sanne Möricke',
            speciality: 'Akkordeon',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Martin Petrov',
            speciality: 'Gajda, Perkussion, Gesang',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Oliver Goers',
            speciality: 'Perkussion, Gesang',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Tina Hess',
            speciality: 'Tanzmeisterin',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Doris Schneider',
            speciality: 'Tanzmeisterin',
            image: '',
            youtube: '',
            spotify: '',
          },
        ],
        description: '',
        eventType: 'Konzert',
        video: '',
        time: '20:00',
        doorsOpen: '19:30',
        entrance: '',
        googleLocation:
          'https://www.google.com/maps/place/Haus+der+Sinne/@52.5491318,13.3993861,17z/data=!3m1!4b1!4m6!3m5!1s0x47a8521b504a4e1d:0x8b7e06018e6373de!8m2!3d52.5491319!4d13.4042516!16s%2Fg%2F1tdck_b3?entry=ttu',
        locationName: 'Haus der Sinne',
        locationAddress: 'Ystader Straße 10, 10437 Berlin',
      },
    ],
    supplementary: '',
  },
  {
    id: '4',
    url: 'kickoff-night',
    title: 'Kickoff night to spread the provokatsia vibes!',
    description: `<p><h3>Und hier sind wir, liebe Leute!</h3>
    Dies ist der Auftakt zu vielen weiteren Abenden, die noch kommen werden!<br />
    Eine Aufwärmsitzung, um euch auf das Kommende vorzubereiten!<br />
    Eine unvergessliche Nacht der Begegnung!<br />
    Ein Vorgeschmack auf die Welt von Provokatsia!</p>
    <p>Und das alles in den Rhythmen von</p>
    <p>
    ---------------<br />
    Roots reggae<br />
    Dub &<br />
    Afro Musik<br />
    --------------</p>
    <p>Lasst uns treffen! Lasst uns tanzen! Lasst uns Liebe verbreiten!<br />
    Und umarmt gemeinsam die Provokatsia-Vibes!</p>
    `,
    poster: 'kickoff-night.jpg',
    tip: '',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2023-12-04T21:00:00.349Z',
        endDate: '2023-12-05T05:00:00.349Z',
        title: '',
        poster: '',
        artists: [
          {
            name: 'Ital-b toaster',
            speciality: 'Greece',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Pikoz Apikoz',
            speciality: 'Berlin',
            image: '',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Joolee Rankin',
            speciality: 'France',
            image: '',
            youtube: '',
            spotify: '',
          },
        ],
        description: '',
        eventType: 'DJ Set',
        video: '',
        time: '20:00',
        doorsOpen: '20:00',
        entrance: '',
        googleLocation:
          'https://www.google.com/maps/place/Lauschangriff/@52.5182464,13.4553066,15z/data=!4m6!3m5!1s0x47a84e6653637a9d:0xd32edb6abbf17c08!8m2!3d52.5182464!4d13.4553066!16s%2Fg%2F11bxh9tjcp?entry=ttu',
        locationName: 'Lauschangriff',
        locationAddress: 'Rigaer Str. 103, 10247 Berlin',
      },
    ],
    supplementary: '',
  },
  {
    id: '5',
    url: '3day-tribute-to-rempetiko',
    title: 'Eine 3-tägige Hommage an Rempetiko',
    description: `<h3>Eine musikalische Reise auf den Wegen des Rembetiko</h3>
      <p>Drei Tage, die dem Rembetiko gewidmet sind, mit einem Dokumentarfilm, einer Buchpräsentation sowie Konzerten:</p>
      <p>Vorführung des 80-minütigen Dokumentarfilms „Broken Sound“ - im Mittelpunkt steht die Bouzouki, das Grundinstrument eines Rembetiko-Orchesters</p>
      <p>Präsentation eines Buches über Dimitris Gogos (Bayandéras), einen der wichtigsten Vertreter dieses Genres</p>
      <p>Konzert-Hommage an Dimitris Gogos (Bayandéras), Konzert mit Liedern der größten Rembetikovertreter aus der Vor- und Nachkriegszeit</p>
      `,
    poster: '3day-tribute-to-rempetiko.jpg',
    tip: 'Tipp: Die Anzahl der verfügbaren Tickets/Plätze ist begrenzt. Kommen Sie deshalb möglichst früh! ;)',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2023-12-08T20:30:00.349Z',
        endDate: '2023-12-10T01:00:00.349Z',
        title: 'Rembetiko-Konzert mit bekannten und unbekannten Liedern',
        poster: '',
        artists: [
          {
            name: 'Dinos Bouzanis',
            speciality: 'Gitarre',
            image: 'ntinos.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Sanne Möricke',
            speciality: 'Akkordeon',
            image: 'sanne.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Maria Vakali',
            speciality: 'Gesang',
            image: 'maria.jpg',
            youtube: 'https://www.youtube.com/watch?v=t_TywytZvdw',
            spotify: '',
          },
          {
            name: 'Anestis Barbatsis',
            speciality: 'Bouzouki, Gesang',
            image: 'anestis.png',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Orfeas Tsaktsiras',
            speciality: 'Bouzouki, Gesang',
            image: 'orfeas.jpg',
            youtube: 'https://www.youtube.com/watch?v=4fUozYahKcQ',
            spotify: '',
          },
        ],
        description: '',
        eventType: 'Konzert',
        video: '',
        time: '21:30',
        doorsOpen: '20:30',
        entrance: '',
        googleLocation:
          'https://www.google.com/maps/place/Biergarten+Jockel/@52.4919277,13.4385616,15z/data=!4m6!3m5!1s0x47a84fad83ee12cf:0x32222854a7e82f96!8m2!3d52.4919277!4d13.4385616!16s%2Fg%2F1v16pb3n?entry=ttu',
        locationName: 'Eventhall Jockel',
        locationAddress: 'Ratiborstraße 14c, 10999 Berlin',
      },
      {
        id: '2',
        startDate: '2023-12-09T18:00:00.349Z',
        endDate: '2023-12-09T22:00:00.349Z',
        title: 'Aufführung des griechischen Dokumentarfilms „Broken sound“',
        subtitle:
          'Idee und Umsetzung: Anestis Barbatsis Regie: Foivos Kontogiannis Eng untertitel',
        poster: '',
        artists: [],
        description: `
          <p>Der Musikdokumentarfilm „Broken Sound“ ist ein gemeinsames Werk von Anestis Barbatsis und Fivos Kontogiannis und wurde von Foss Productions gemeinsam mit dem Hellenic Film Center produziert. Fivos Kontogiannis, verantwortlich für die Regie, und Anestis Barbatsis, verantwortlich für die Idee, Recherche, Redaktion, Musikbearbeitung sowie die Auswahl und Interviews der Protagonisten und anderer Sprecher, führen uns auf eine Erinnerungsreise zum Klang des beliebtesten Volksinstrumentes des Landes, erzählt aus der Sicht großer Persönlichkeiten der heutigen Geschichte der Bouzouki. </p>
          <p>Von den dunklen Tekédes (Mehrzahl von Tekés – aus dem Türkischen, in Griechenland im Zusammenhang mit dem Rembetiko häufig als Bezeichnung für Lokale verwendet, in denen man Haschisch rauchen konnte) in den Jahren der Illegalität bis hin zu den großen Bühnen der griechischen Musikgeschichte entfaltet die Bouzouki ihre Seele in den Händen der Musiker, die sie liebten und bekannt machten. Teil dieser visualisierten Erzählung und präsent in diesem

          anspruchsvollen Werk sind große Musiker wie Dimitris Vyzas, Giorgos Dramalis, Manolis Karantinis, Vangelis Liolios, Giannis Moraitis, Christos Nikolopoulos, Giannis Papavasileiou, Kostas Papadopoulos, Manolis Pappos, Thanasis Polykrandriotis, Panagiotis Stergiou, Thymios Stouraitis, Nikos Tatasopoulos, Vangelis Trigas und Diamantis Chiotis , die uns mit ihren Taximia (Improvisationen) und ihren faszinierenden Erzählungen melodisch auf eine Reise durch die Vergangenheit, die Gegenwart und die Zukunft der Bouzouki mitnehmen. </p>
          <p>„Broken Sound“ folgt der musikalischen Entwicklung der Bouzouki. Der Film präsentiert einen historischen und musikalischen Überblick sowie eine kulturelle und soziale Darstellung der Entwicklung der griechischen Gesellschaft durch Interviews mit bedeutenden Persönlichkeiten der heutigen Geschichte des Instruments, kombiniert mit seltenem Archivmaterial. </p>
        `,
        supplementary: `
          <h2>Angaben zum Film</h2>
          <p>Idee und Recherche: Anestis Barbatsis<br />
            Drehbuch: Anestis Barbatsis, Foivos Kontogiannis
          </p>
          <p>Regie: Foivos Kontogiannis </p>
          <p>Redaktion und Musikbearbeitung: Anestis Barbatsis</p>
          <p>
            Produkion: Foss Productions<br />
            Co-Produktion: Hellenic Cinema Center, Faliro House, EKOME<br />
            Produzent: Stelios Kotionis<br />
            Ausführender Produzent: Christos V. Konstantakopoulos<br />
            Kamera: Manu Tilinski<br />
            Bearbeitung: George Georgopoulos<br />
            Ton: Stavros Avramidis, Leandros Dounis<br />
          </p>
        `,
        eventType: 'Dokumentarfilmvorführung',
        time: "19:00 | Dauer: 80'",
        doorsOpen: '18:00',
        videoId: '4Xf8JWi2jFg',
        entrance: 'Eintritt frei ',
        googleLocation:
          'https://www.google.com/maps/place/BAVUL+-+Kunst+%26+Kultur+Caf%C3%A9/@52.5093458,13.4121714,15z/data=!4m6!3m5!1s0x47a84f3c13e1227b:0x259905d739f1b5c2!8m2!3d52.5093458!4d13.4121714!16s%2Fg%2F11fm2m1wvj?entry=ttu',
        locationName: 'Bavul',
        locationAddress: 'Annenstraße 13, 10179 Berlin',
      },
      {
        id: '3',
        startDate: '2023-12-10T17:00:00.349Z',
        endDate: '2023-12-10T22:00:00.349Z',
        title:
          'Buchpräsentation und Konzert in Würdigung des Werkes des Rembetiko-Musikers Bayiantéras',
        poster: 'mpagianteras.jpg',
        artists: [
          {
            name: 'Dinos Bouzanis',
            speciality: 'Gitarre',
            image: 'ntinos.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Sanne Möricke',
            speciality: 'Akkordeon',
            image: 'sanne.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Maria Vakali',
            speciality: 'Gesang',
            image: 'maria.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Anestis Barbatsis',
            speciality: 'Bouzouki, Gesang',
            image: 'anestis.png',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Giorgos Evaggelou',
            speciality: 'Bouzouki, Gesang',
            image: 'giorgos.jpg',
            youtube: '',
            spotify: '',
          },
          {
            name: 'Orfeas Tsaktsiras',
            speciality: 'Bouzouki, Gesang',
            image: 'orfeas.jpg',
            youtube: 'https://www.youtube.com/watch?v=4fUozYahKcQ',
            spotify: '',
          },
        ],
        description: `
            <h3>Buchpräsentation</h3>
            <p>Präsentation des Buches: Anestis Barbatsis „Bayiantéras – Dimitris Gogos, Die Vorkriegsperiode“ (Herausgeber: Metronomos 2022). </p>
            <p>Dimitris Gogos oder Bayiantéras wurde 1903 in Piräus geboren.</p>
            <p> Er wuchs in Hatzikyriakeio auf und erlebte vor seiner ersten Schallplattenaufnahme die frühe Periode des „piräotischen“ Rembetiko mit der Bouzouki als Grundlage. Die Umstände, unter denen er aufwuchs, und seine musikalische Karriere machen ihn zu einem der wichtigsten Vertreter des Rembetiko.</p>
            <p>Anestis Barbatsis konzentriert sich in seinem Buch auf die Vorstellung und Analyse des Schaffens von Bayiantéras aus der Vorkriegszeit, wobei er wichtige Zeugnisse über dessen Leben und Werk erbringt, die die Entwicklung des Komponisten dokumentieren und zum ersten Mal veröffentlicht werden. </p>
          `,
        eventType: 'Buchpräsentation + Konzert',
        time: 'Buchpräsentation: 18:00 | Konzert: 19:00',
        doorsOpen: '17:00',
        video: '',
        entrance: 'Eintritt frei',
        googleLocation:
          'https://www.google.com/maps/place/Biergarten+Jockel/@52.4919277,13.4385616,15z/data=!4m6!3m5!1s0x47a84fad83ee12cf:0x32222854a7e82f96!8m2!3d52.4919277!4d13.4385616!16s%2Fg%2F1v16pb3n?entry=ttu',
        locationName: 'Eventhall Jockel',
        locationAddress: 'Ratiborstraße 14c, 10999 Berlin',
      },
    ],
    supplementary: `<h2>Rembetika – der griechische Blues</h2>
        <p><i>"Ein ähnliches künstlerisches Schaffen lässt sich in Amerika beim Blues, in Brasilien beim Samba und in Jamaika beim Reggae beobachten."</i></p>
        <p><i>"Der Rembetiko ist ein urbanes Volkslied, dessen Wurzeln auf byzantinische Musik und griechische Volkslieder zurückgehen."</i></p>
        <p><i>"Rembetis: Ungehorsam, rebellisch, unabhängig."</i></p>
        <p><i>"Rembetika sind Lieder des Herzens und nur, wer sich ihnen mit reinem Gefühl nähert, fühlt und genießt sie."</i></p>
        <p><i>"Dem Rembetiko gelingt es, Sprache, Musik und Bewegung in wunderbarer Einheit zu verbinden."</i></p>
        <p><i>"Von der Komposition bis zur Ausführung werden instinktiv die Voraussetzungen für dieses dreifache Zusammenwirken geschaffen, das manchmal zur Perfektion zu gelangen scheint und an eine antike Tragödie erinnert."</i></p>
        <p>Auszug aus dem Buch „Rembetiko-Lieder“ von Ilias Petropoulos</p>
        <p><i>"Das Rembetiko-Lied ist wirklich griechisch, einzigartig griechisch."</i></p>
        <p>Manos Chatzidakis</p>
      `,
  },
  {
    id: '6',
    url: 'saligari-meets-provokatsia',
    title: 'Saligari meets provokatsia',
    description: `<p>Während das Jahr 2023 zu Ende geht, beginnen in Berlin neue Kapitel. Unser geliebtes Saligari zieht 
    in eine neue Location mit einem neuen Konzept um und feiert diesen Samstag zusammen mit dem Provokatia Project Team 
    unsere neuen Reisen ins Unbekannte! Wir freuen uns darauf, euch alle im Saligari Bar zu treffen, uns kennenzulernen, 
    zu reden, Ideen auszutauschen, großartige Musik zu hören und einzigartige Cocktails zu trinken.</p>
    `,
    poster: 'saligari-meets-provokatsia.jpg',
    tip: '',
    isSuggested: false,
    days: [
      {
        id: '1',
        startDate: '2023-12-16T18:00:00.349Z',
        endDate: '2023-12-17T06:00:00.349Z',
        title: '',
        poster: '',
        artists: [],
        description: '',
        eventType: 'DJ Set',
        video: '',
        time: '19:00',
        doorsOpen: '19:00',
        entrance: 'Eintritt Frei',
        googleLocation:
          'https://www.google.com/maps/place/Simplonstra%C3%9Fe+23,+10245+Berlin/@52.5084283,13.4592078,17z/data=!3m1!4b1!4m6!3m5!1s0x47a84e58dd616c81:0xd36c8ab82bfa3077!8m2!3d52.5084283!4d13.4592078!16s%2Fg%2F11c1z9gkkd?entry=ttu',
        locationName: 'Saligari bar',
        locationAddress: 'Simplonstrasse 23, 10245 Berlin',
      },
    ],
    supplementary: '',
  },
]

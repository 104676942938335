import styled from 'styled-components'

export const StyledHome = styled.div`
  h1 {
    margin-top: 0;
  }

  .info {
    margin-bottom: 100px;
    display: flex;
    align-items: flex-start;
    gap: 50px;
  }

  @media (max-width: 1023px) {
    .info {
      flex-direction: column;
      align-items: center;
    }

    .welcome {
      order: 1;
    }
  }

  .welcome h2 {
    margin-top: 50px;
  }

  .newsletter-form {
    flex: 0 0 40%;
  }

  @media (max-width: 1023px) {
    .newsletter-form {
      order: 2;
    }
  }
`

import styled from 'styled-components'

export const StyledEventsList = styled.div`
  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 25px;
    padding: 0;
    margin: 0;
  }

  ul li {
    list-style: none;
  }

  @media (max-width: 1023px) {
    ul {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width: 820px) {
    ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 576px) {
    ul {
      grid-template-columns: 100%;
    }
  }

  .event {
    border-radius: 10px;
    position: relative;
  }

  .event .event__poster img {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
  }

  .event:hover .event__poster img {
    transform: scale(1.15);
  }

  .event__poster {
    width: 100%;
    height: 150px;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
  }

  .event__tag {
    position: absolute;
    top: -13px;
    right: 15px;
    background-color: var(--secondary-color);
    border-radius: 15px;
    padding: 2px 5px;
    font-size: 0.75rem;
    z-index: 1;
    border: 4px solid #222;
  }

  .event__details {
    display: flex;
    padding-top: 15px;
  }

  .event__title h3,
  .event__title p {
    margin: 0;
  }

  .event__title h3 a {
    font-size: 1.125rem;
    text-decoration: none;
  }

  .event__title p {
    color: #bababa;
    font-size: 0.875rem;
  }
`
